import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store/redux';
import { PersistGate } from 'redux-persist/integration/react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
import firestar from 'firestar'

const firebaseConfig = {
  apiKey: "AIzaSyCOPg09ZqJayfSIVdiyn6JFUQEF5IPv2T4",
  authDomain: "continuity-gifting-app.firebaseapp.com",
  databaseURL: "https://continuity-gifting-app-default-rtdb.firebaseio.com",
  projectId: "continuity-gifting-app",
  storageBucket: "continuity-gifting-app.appspot.com",
  messagingSenderId: "814625968432",
  appId: "1:814625968432:web:0100e8d22addd9ab9eb8f5",
  measurementId: "G-B0Y29XP71P"
};

const env = process.env.REACT_APP_ENV

if (firebase.apps.length == 0) {
  const app = firebase.initializeApp(firebaseConfig)
  firebase.analytics()
  firestar.init(
    'console',
    'browser',
    () => firebase.firestore,
    () => firebase.auth,
    () => firebase.storage,
    () => firebase.database,
    env == 'emulator' ? {
      app: app as any,
      host: 'localhost',
      port: {
        auth: 9099,
        database: 9000,
        firestore: 8080,
        storage: 9199
      }
    } : undefined
  )
}

ReactDOM.render(
  <React.StrictMode>
    <style type="text/css">{`
        @font-face {
      font-family: 'MaterialIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
    } 
      @font-face {
        font-family: 'FontAwesome';
        src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
      }
    `}</style>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          {env == 'emulator' && <div style={{
            background: '#32a891',
            width: '100%',
            textAlign: 'center',
            color: 'white'
          }}>{env}</div>}
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
