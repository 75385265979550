import React, { createContext, useContext, useState } from 'react'
import { Promotions } from 'cs-connect';
import { useSelector } from 'react-redux'
import { AppState } from '../store';

type PromotionsContextProps = {
	promotions: Promotions.Promotion[],
	fetchPromotions: () => Promise<Promotions.Promotion[]>,
	deletePromotion: (promotionId: string) => Promise<void>,
	fetchPromotion: (promotionId: string) => Promise<Promotions.Promotion>,
	addPromotion: (document: Promotions.Promotion) => Promise<Promotions.Promotion>,
	updatePromotion: (promotionId: string, document: Promotions.Promotion | Partial<Promotions.Promotion>) => Promise<Promotions.Promotion | Partial<Promotions.Promotion>>
}

const PromotionsContext = createContext<PromotionsContextProps>({
	promotions: [],
} as unknown as PromotionsContextProps)

export const usePromotionsContext = () => useContext(PromotionsContext);

const PromotionsProvider = (props:{
	children
}) => {

	const [promotions, setPromotions] = useState<Promotions.Promotion[]>([])
	const settings = useSelector((state: AppState) => state.settings);
	const { children } = props

	return (
		<PromotionsContext.Provider value={{
			promotions,
			fetchPromotions,
			deletePromotion,
			fetchPromotion,
			addPromotion,
			updatePromotion
		}}>
			<>{children}</>
		</PromotionsContext.Provider>
	)

	function fetchPromotion(promotionId: string) {
		return new Promise<Promotions.Promotion>(async (resolve, reject) => {
			try {
				const casinoId = settings?.linkedCasinoId
				if (casinoId) {
					const response = await Promotions.getPromotion(casinoId, promotionId)
					resolve(response)
				} else {
					reject('No Casino Id found in settings')
				}
			} catch (error) {
				reject(error)
			}
		})
	}

	function fetchPromotions() {
		return new Promise<Promotions.Promotion[]>(async (resolve, reject) => {
			try {
				const casinoId = settings?.linkedCasinoId
				if (casinoId) {
					const response = await Promotions.getPromotions(casinoId, {
						orderBy: {
							fieldPath: 'updatedAt',
							directionStr: 'desc'
						},
						limit: 150
					})
					setPromotions(response.data)
					resolve(response.data)
				} else {
					reject('No Casino Id found in settings')
				}
			} catch (error) {
				reject(error)
			}
		})
	}

	function deletePromotion(promotionId: string) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const casinoId = settings?.linkedCasinoId
				if (casinoId) {
					await Promotions.deletePromotion(casinoId, promotionId)
					const __promotions = [...promotions]
					const deleteIndex = __promotions.findIndex((p) => p.id === promotionId)
					if (deleteIndex >= 0){
						__promotions.splice(deleteIndex, 1)
					}
					setPromotions(__promotions)
					resolve()
				} else {
					reject('No Casino Id found in settings')
				}
			} catch (error) {
				reject(error)
			}
		})
	}

	function addPromotion(document: Promotions.Promotion) {
		return new Promise<Promotions.Promotion>(async (resolve, reject) => {
			try {
				const casinoId = settings?.linkedCasinoId
				if (casinoId) {
					const response = await Promotions.addPromotion(casinoId, document)
					resolve(response)
				} else {
					reject('No Casino Id found in settings')
				}
			} catch (error) {
				reject(error)
			}
		})
	}

	function updatePromotion(promotionId: string, document: Promotions.Promotion | Partial<Promotions.Promotion>){
		return new Promise<Promotions.Promotion | Partial<Promotions.Promotion>>(async (resolve, reject) => {
			try {
				const casinoId = settings?.linkedCasinoId
				if (casinoId) {
				   const response = await Promotions.updatePromotion(
					  casinoId,
					  promotionId,
					  document
				   )
				   resolve(response)
				} else {
				   reject('No Casino Id found in settings')
				}
			 } catch (error) {
				reject(error)
			 }
		})
	}
}

export default PromotionsProvider