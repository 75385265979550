import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import firestar from "firestar";
import firebase from "firebase/compat/app";
import Progress from "./utils/Progress";
import SelectCasino from "./SelectCasino";
import { useSelector } from "react-redux";
import { AppState } from "./store";
import { Users } from "cs-connect";
import ResetPassword from "./ResetPassword";
import PromotionsProvider from "./containers/PromotionsContext";

export const RoleContext = React.createContext<{
	role: Users.Role | null;
	updateRole: (role: Users.Role) => void;
}>({
	role: null,
	updateRole: () => null,
});

export default function App() {
	const [userId, setUserId] = useState<string | undefined>(
		firestar.getUserId()
	);
	const [showLoader, setShowLoader] = useState(true);
	const settings = useSelector((state: AppState) => state.settings);
	const linkedCasinoId = settings?.linkedCasinoId
	const [role, setRole] = useState<Users.Role | null>(null)

	useEffect(() => {
		firebase.auth().onAuthStateChanged(onAuthStateChanged);
	}, []);

	useEffect(() => {
		if (userId) {
			setShowLoader(true)
			Users.getClaims()
				.then((claims) => {
					const roles = claims.roles ?? {}
					if (linkedCasinoId) {
						const role = roles[linkedCasinoId] || roles['account']
						setRole(role)
					}
					setShowLoader(false);
				})
				.catch((error) => console.error('Error Getting Claims', error))
		}
	}, [userId])

	if (showLoader == true) return <Progress />;

	return (
		<RoleContext.Provider value={{
			role: role,
			updateRole: updateRole
		}}>
			<View>
				{userId == null ? (
					<Switch>
						<Route exact path="/" component={Login} />
						<Route path="/reset-password" component={ResetPassword} />
					</Switch>
				) : (
					<PromotionsProvider>
						<Switch>
							{linkedCasinoId == null ? (
								<Route path="/" component={SelectCasino} />
							) : (
								<Route path="/" component={Home} />
							)}
						</Switch>
					</PromotionsProvider>

				)}
			</View>
		</RoleContext.Provider>
	);

	function updateRole(role: Users.Role) {
		setRole(role)
	}

	function onAuthStateChanged(state) {
		const user = state as firebase.UserInfo;
		setUserId(user?.uid)
		setShowLoader(false)
	}
}
