import {
   createStore,
   applyMiddleware,
} from 'redux'

import {
   persistStore,
   persistReducer
} from 'redux-persist'

import AsyncStorage from '@react-native-async-storage/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'

import rootReducer from '.'

const middleware = [thunk]

const persistConfig = {
   key: 'root',
   timeout: 0,
   storage: AsyncStorage,
   autoMergeLevel2,
   transforms: [],
   whitelist: ['settings']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
   persistedReducer,
   composeWithDevTools(applyMiddleware(...middleware))
)

export const persistor = persistStore(store)
