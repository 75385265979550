import { Change, AppState } from '.'
import {
   Casinos,
   Users
} from 'cs-connect'
import firestar from 'firestar';


const initialState: Casinos.Casino | null = null

export default function handler(state = initialState, action: Change) {
   switch (action.type) {
      case 'GET_CASINO': {
         return {
            ...action.payload
         }
      }
      case 'CLEAR_CASINO': {
         return null
      }
      default:
         return state
   }
}

export function getLoggedInCasino() {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise(async (resolve, reject) => {

         try {

            const userId = firestar.getUserId()
            if (userId == null) throw new Error('Not Logged In')
            
            const claims = await Users.getClaims()
            const roles = claims.roles ?? {}
            const settings = getState().settings
            const casinoId = settings?.linkedCasinoId
            const role = roles[casinoId ?? ''] || roles['account']
            if (role == null) {

               dispatch({
                  type: 'LINK_CASINO_ID',
                  payload: null
               })
               throw new Error('You do not have access to this access. Contact Casino Admin.')
            }

            if (casinoId) {
               const response = await Casinos.getCasinoWithId(casinoId)
               console.log('Response', response)
               dispatch({
                  type: 'GET_CASINO',
                  payload: response
               })
               resolve(response)
            } else {
               reject('No Casino Id found in settings')
            }
         } catch (error) {
            reject(error)
         }
      })
   }
}