import React, { useContext } from 'react'
import { View, Text } from 'react-native'
import { RoleContext } from './App'

export default function Dashboard() {

   const roleContext = useContext(RoleContext)

   return (
      <View>
         <Text>Name: {roleContext.role?.name}</Text>
         <Text>Level: {roleContext.role?.level}</Text>
         <Text>Id: {roleContext.role?.id}</Text>
      </View>
   )
}
