import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import {
   Link, useHistory
} from 'react-router-dom'
import Button from './components/Button'
import firebase from 'firebase/compat/app';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UsersIcon from '@mui/icons-material/VpnKey';
import SettingsIcon from '@mui/icons-material/Settings';
import MembersIcon from '@mui/icons-material/CardGiftcard';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import Main from './Main';
import { AppState } from './store';
import {
   useSelector,
   useDispatch
} from 'react-redux'
import Progress from './utils/Progress.web';
import { getLoggedInCasino } from './store/casino';
import { unlinkCasino } from './store/settings';
import { Alert } from './utils/Alert.web';
import { Users } from 'cs-connect';

const RoleContext = React.createContext<Users.Role | null>(null)

const menuItems = [
   {
      id: '/dashboard',
      title: 'Dashboard',
      icon: () => <DashboardIcon />
   },
   {
      id: '/promotions',
      title: 'Promotions',
      icon: () => <MembersIcon />
   },
   {
      id: '/users',
      title: 'Users & Roles',
      icon: () => <UsersIcon />
   },
   {
      id: '/settings',
      title: 'Settings',
      icon: () => <SettingsIcon />
   }
]

export default function Home() {

   const [showMenu, setShowMenu] = useState(false)
   const casino = useSelector((state: AppState) => state.casino)
   const [showLoader, setShowLoader] = useState(false)
   const dispatch = useDispatch()
   const history = useHistory()

   useEffect(() => {
      if (casino == null) {
         fetchCasino()
      }
   }, [casino])

   async function fetchCasino() {
      setShowLoader(true)
      try {
         await dispatch(getLoggedInCasino())
      } catch (error) {
         console.log('Error Logging in to Casino : ',error)
         Alert.alert('Error Logging in to Casino', String(error))
      }
      setShowLoader(false)
   }

   if (showLoader == true) {
      return <Progress />
   }

   return (
      <Box sx={{ flexGrow: 1 }}>
         <AppBar position="static">
            <Toolbar>
               <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1
               }}>
                  <View style={{
                     flexDirection: 'row',
                     alignItems: 'center',
                     justifyContent: 'center'
                  }}>
                     <IconButton
                        onClick={toggleMenu}
                        size="large"
                        edge="start"
                        color="inherit"
                        sx={{ mr: 2 }}>
                        <MenuIcon />
                     </IconButton>
                     <Text style={{
                        color: 'white',
                        fontSize: 20
                     }}>{casino?.name}</Text>
                     <IconButton style={{
                        marginLeft: 5
                     }} onClick={onPressUnlink}>
                        <LogoutIcon htmlColor='white' />
                     </IconButton>
                  </View>
                  <Button
                     onPress={onClickLogout}
                     type='text'
                     titleStyle={{
                        color: 'white',
                        fontSize: 14,
                        fontWeight: 'bold'
                     }}
                     title='LOGOUT' />
               </View>
            </Toolbar>
            <Drawer
               anchor={'left'}
               open={showMenu}
               onClose={toggleMenu}>
               {menuItems.map(({ icon, id, title }) => (
                  <Link to={id} onClick={toggleMenu} style={{
                     textDecoration: 'none'
                  }}>
                     <View style={{
                        paddingHorizontal: 55,
                        paddingLeft: 25,
                        paddingVertical: 15,
                        borderBottomWidth: 1,
                        borderColor: '#dddddd',
                        flexDirection: 'row',
                        alignItems: 'center',
                     }}>
                        {icon()}
                        <Text style={{
                           marginLeft: 15
                        }}>{title}</Text>
                     </View>
                  </Link>
               ))}
            </Drawer>
         </AppBar>
         <View style={{
            padding: 15
         }}>
            <Main />
         </View>
      </Box>
   )

   function toggleMenu() {
      setShowMenu(!showMenu)
   }

   function onPressUnlink() {
      dispatch(unlinkCasino())
      history.replace('/')
   }

   function onClickLogout() {
      firebase.auth().signOut()
      history.replace('/')
   }
}
