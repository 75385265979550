import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import TextField from './utils/TextField'
import Grid from '@mui/material/Grid';
import Button from './components/Button'
import Progress from './utils/Progress';
import firebase from 'firebase/compat/app';
import { Alert } from './utils/Alert.web';
import { Link } from 'react-router-dom';

const styles = StyleSheet.create({
   textField: {
      marginVertical: 5
   }
})

export default function Login() {

   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const [showLoader, setShowLoader] = useState(false)

   return (
      <Grid style={{
         alignSelf: 'center',
         justifyContent: "center"
      }} container xs={12} sm={8} md={6} lg={4} xl={4}>
         {showLoader && <Progress />}
         <View style={{
            flexDirection: 'column',
            flex: 1,
            padding: 20
         }}>
            <Text style={{
               textAlign: 'center',
               fontSize: 60,
               fontWeight: '200',
               marginVertical: 50
            }}>InfiGifts</Text>
            <TextField
               label='Email'
               keyboardType='email-address'
               value={email}
               style={styles.textField}
               onChangeText={onChangeEmail}
               autoCorrect={false}
               autoCapitalize='none' />
            <TextField
               label='Password'
               keyboardType='visible-password'
               secureTextEntry
               value={password}
               style={[styles.textField, {
                  marginBottom: 0
               }]}
               onChangeText={onChangePassword}
               autoCorrect={false}
               autoCapitalize='none' />
            <View style={{
               flexDirection: 'row',
               justifyContent: 'space-between'
            }}>
               <View />
               <Link to={'/reset-password'} style={{
                  textDecoration: 'none',
                  marginTop: '0.2em'
               }}>
                  <div>Forgot Password?</div>
               </Link>
            </View>
			   <Button
				   style={{
					   margin: '20 0',
					   borderRadius: 2,
					   height: 50,
					   fontWeight: 'bold',
					   fontSize: 15
				   }}
				   disabled={showLoader ||
					   email?.trim().length == 0 ||
					   password?.trim().length == 0}
				   onPress={onPressLogin}
				   title='LOGIN' />
			   <br />
			   <Button
				   onPress={onPressGoogleLogin}
				   title='Login with Google' />
		   </View>
	   </Grid>
   )

   function onChangeEmail(value) {
      setEmail(value.trim())
   }

   function onChangePassword(value) {
      setPassword(value.trim())
   }

   async function onPressLogin() {
      setShowLoader(true)
      try {
         await firebase.auth().signInWithEmailAndPassword(email, password)
      } catch (error) {
         console.log('Error Logggin In : ', error)
         setShowLoader(false)
         Alert.alert(
            'Error Loggin In',
            String(error)
         )
      }
   }

	async function onPressGoogleLogin() {

		const provider = new firebase.auth.GoogleAuthProvider()
		setShowLoader(true)
		provider.setCustomParameters({ prompt: 'select_account' });
		try {
			const popUpResponse = await firebase.auth().signInWithPopup(provider)
			if (popUpResponse.credential != null) {
				await firebase.auth().signInWithCredential(popUpResponse.credential)
			}
		} catch (error) {
			console.log('Error SignIn with Pop Up', error)
			Alert.alert('Error Signing In', String(error))
		}
		setShowLoader(false)
	}
}
