/* eslint-disable no-loop-func */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { View, Text } from 'react-native'
import CSVReader, {
   IFileInfo
} from 'react-csv-reader'
import Members, { GenderOptions } from 'cs-connect/lib/Members';
import {
   Casinos, Promotions, Users
} from 'cs-connect'
import {
   useParams,
   useHistory
} from 'react-router-dom';
import MaterialTable, { Column } from 'material-table'
import {
   deflattenObject,
   exportListToCSV,
   getStates
} from 'cs-connect/lib/Utils'
import {
   useSelector
} from 'react-redux'
import lodash from 'lodash';
import { AppState } from './store';
import { formatName, convertToTypeOf, validateEmail, validatePhone, useDebounce } from './utils';
import Progress from './utils/Progress';
import { Alert } from './utils/Alert.web'
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import Modal from '@mui/material/Modal'
import Message from '@mui/icons-material/Sms'
import Member from './Member';
import Icon from './components/Icon'
import SearchBar from './components/SearchBar'
import {
   WhereType,
   LastDocType,
   Actions,
   OrderType as OrderBy
} from 'firestar'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import {
   getFirestore,
   collection,
   getCountFromServer
} from 'firebase/firestore'
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import CheckPermission from './utils/CheckPermission';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import SaveIcon from '@material-ui/icons/Save'
import ErrorIcon from '@material-ui/icons/Error'
import HighlightOff from '@material-ui/icons/HighlightOffOutlined'

const generateClassName = createGenerateClassName({
	productionPrefix: 'mt',
	seed: 'mt'
}); //To prevent material table from changing appbar style


export const StateOptions = {
   null: '--'
}
getStates().forEach((state) => {
   StateOptions[state.code] = state.name
})

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

export interface CustomColumn extends Column<Members.Member>{
   formatValue?: (value?: any) => void,
}

const getColumns = (casinoId: string, promotion: Promotions.Promotion): CustomColumn[] => {
   const promotionId = promotion.id
   return [
      {
         field: 'cardNumber',
         headerStyle: { fontWeight: 'bold' },
         title: 'Card Number',
         editable: 'always',
         type: 'string',
         validate: (member) => (member?.cardNumber ?? '').trim().length > 0,
      },
      {
         field: 'tier',
         headerStyle: { fontWeight: 'bold' },
         title: 'Tier',
         editable: 'always',
         type: 'numeric',
         initialEditValue: null,
      },
      {
         field: 'id',
         headerStyle: { fontWeight: 'bold' },
         title: 'ID',
         editable: 'never',
         type: 'string',
         editPlaceholder: 'No ID Assigned',
         render: (member) => (
            <div>
               <div>{member.id}</div>
               <CheckPermission minimumRole="admin">
                  {member.orderInfo && <div style={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center'
                  }}>{member.orderInfo?.name}
                     <a target='_blank' href={`https://admin.infigifts.com/orders/${member.orderInfo.id}`}><LaunchIcon/></a>
                  </div>}
               </CheckPermission>
            </div>
         )
      },
      {
         headerStyle: { fontWeight: 'bold' },
         field: 'enabled',
         title: 'Enabled',
         render: (member) => (
            <input 
               style={{ pointerEvents: 'none' }}
               type={'checkbox'} 
               checked={member.enabled ?? promotion.enableMemberByDefault ?? true}/>
         ),
         initialEditValue: promotion.enableMemberByDefault ?? true,
         editable: 'always',
         type: 'boolean',
         cellStyle:{
            textAlign: 'center'
         },
         hidden: (promotion.enableMemberByDefault ?? true) === true
      },
      {
         headerStyle: { fontWeight: 'bold' },
         title: 'Url',
         editable: 'never',
         type: 'string',
         render: (member) => (
            <a target={'_blank'} href={`https://app.infigifts.com/casinos/${casinoId}/promotions/${promotionId}?cardNumber=${member.cardNumber}`}>
               <OpenInBrowserIcon />
            </a>
         )
      },
      {
         field: 'firstName',
         headerStyle: { fontWeight: 'bold' },
         title: 'First Name',
         editable: 'onAdd',
         type: 'string',
         validate: (member) => (member?.firstName ?? '').trim().length > 0
      },
      {
         field: 'lastName',
         headerStyle: { fontWeight: 'bold' },
         title: 'Last Name',
         editable: 'onAdd',
         type: 'string',
         validate: (member) => (member?.lastName ?? '').trim().length > 0
      },
      {
         field: 'name',
         headerStyle: { fontWeight: 'bold' },
         title: 'Name',
         editable: 'never',
         type: 'string',
         emptyValue: (member: Members.Member) => {
            return formatName(member?.firstName, member?.lastName)
         },
      },
      {
         field: 'email',
         headerStyle: { fontWeight: 'bold' },
         title: 'Email',
         editable: 'always',
         type: 'string',
         validate: (member) => {
            const email = (member.email ?? '').trim()
            return email.length > 0 ? validateEmail(email) : true
         },
         render: (member) => (
            <Text style={{
               borderWidth: 2,
               borderColor: (member.email ?? '').trim().length > 0 ? (validateEmail(member.email) ? 'white' : 'red') : 'white',
               padding: 2,
               borderRadius: 5
            }}>{member.email}</Text>
         ),
         formatValue: (value) => value?.trim?.() ?? null
      },
      {
         field: 'phoneNumber',
         headerStyle: { fontWeight: 'bold' },
         title: 'Phone Number',
         editable: 'always',
         type: 'string',
         validate: (member) => (member.phoneNumber ?? '').length > 0 ? validatePhone(member.phoneNumber) : true,
         render: (member) => (
            <Text style={{
               borderWidth: 2,
               borderColor: ((member.phoneNumber ?? '').length > 0 ? validatePhone(member.phoneNumber) : true) ? 'white' : 'red',
               padding: 2,
               borderRadius: 5
            }}>{member.phoneNumber}</Text>
         ),
         formatValue: (value) => formatPhoneNumber(value ?? '')
      },
      {
         field: 'age',
         headerStyle: { fontWeight: 'bold' },
         title: 'Age',
         editable: 'onAdd',
         type: 'numeric'
      },
      {
         field: 'gender',
         headerStyle: { fontWeight: 'bold' },
         title: 'Gender',
         editable: 'always',
         type: 'string',
         lookup: GenderOptions,
         initialEditValue: null
      },
      {
         field: 'address.street',
         headerStyle: { fontWeight: 'bold' },
         title: 'Street',
         editable: 'always',
         type: 'string',
         validate: (member) => (member.address?.street ?? '').length > 0
      },
      {
         field: 'address.city',
         headerStyle: { fontWeight: 'bold' },
         title: 'City',
         editable: 'always',
         type: 'string',
         validate: (member) => (member.address?.city ?? '').length > 0
      },
      {
         field: 'address.stateCode',
         headerStyle: { fontWeight: 'bold' },
         title: 'State',
         editable: 'always',
         type: 'string',
         lookup: StateOptions,
         validate: (member) => (member.address?.stateCode ?? '').length > 0,
		 formatValue: (value) => value?.trim?.() ?? null
      },
      {
         field: 'address.state',
         headerStyle: { fontWeight: 'bold' },
         title: 'State',
         editable: 'always',
         type: 'string',
         validate: (member) => (member.address?.state ?? '').length > 0,
         hidden: true,
         emptyValue: (member: Members.Member) => {
            return StateOptions[member.address?.stateCode] ?? null
         },
      },
      {
         field: 'address.country',
         headerStyle: { fontWeight: 'bold' },
         title: 'Country',
         editable: 'always',
         type: 'string',
         initialEditValue: 'United States',
         validate: (member) => (member.address?.country ?? '').length > 0
      },
      {
         field: 'address.zipCode',
         headerStyle: { fontWeight: 'bold' },
         title: 'Zip Code',
         editable: 'always',
         type: 'string',
         validate: (member) => (member.address?.zipCode ?? '').length > 0
      },
      {
         headerStyle: { fontWeight: 'bold' },
         title: 'Created At',
         editable: 'never',
         render: (member) => (
            <div style={{
               fontSize: 12
            }}>
               {member.createdAt && <div>
                  <div>{moment(member.createdAt?.toDate()).fromNow() ?? '--'}</div>
                  <div>{moment(member.createdAt?.toDate()).format('MM/DD/YYYY - hh:mm a ') ?? '--'}</div>
               </div>}
               <div>{member.createdBy?.displayName ?? '--'}</div>
            </div>
         )
      },
      {
         headerStyle: { fontWeight: 'bold' },
         title: 'Last Updated',
         editable: 'never',
         render: (member) => (
            <div style={{
               fontSize: 12
            }}>
               {member.lastUpdatedAt && <div>
                  <div>{moment(member.lastUpdatedAt?.toDate()).fromNow() ?? '--'}</div>
                  <div>{moment(member.lastUpdatedAt?.toDate()).format('MM/DD/YYYY - hh:mm a ') ?? '--'}</div>
               </div>}
               <div>{member.lastUpdatedBy?.displayName ?? '--'}</div>
            </div>
         )
      }
   ]
}

export default function MembersList() {

   const [csvData, setCsvData] = useState<any[]>()

   const settings = useSelector((state: AppState) => state.settings)
   const casino = useSelector((state: AppState) => state.casino)

   const [casinoInfo] = useState(Casinos.getCasinoInfo(casino ?? {}))
   const [searchText, setSearchText] = useState('')
   const debouncedSearchTerm = useDebounce(searchText, 500);

   const params = useParams() as {
      promotionId: string
   }

   const isAdding  = useMemo(() => csvData != null, [csvData])

   const casinoId = settings?.linkedCasinoId
   const promotionId = params.promotionId
   const [showLoader, setShowLoader] = useState(false)
   const [lastDoc, setLastDoc] = useState<LastDocType>()
   const [promotion, setPromotion] = useState<Promotions.Promotion>()
   const [uploadErrors, setUploadErrors] = useState<Members.Member[]>([])
   const uploadKeysRef = useRef<{[key: string] : string }>({})
   const [selectedUploadKeys, setSelectedUploadKeys] = useState({})
   const [validationSuccessList, setValidationSuccessList] = useState<Members.Member[]>([])
   const [showClaimedMembers, setShowClaimedMembers] = useState(false)
   const columns = useMemo(() => {
      if (casinoId != null && promotion != null) {
         return getColumns(casinoId, promotion)
      }
      return []
   }, [casinoId, promotion])

   const [members, setMembers] = useState<Members.Member[]>([])
   const [totalCount, setTotalCount] = useState<number>()
   const saveButtonStatus = members.length === 0

   const history = useHistory()
   const [editMember, setEditMember] = useState<string>()
   const [uploadProgress, setUploadProgress] = useState<{
      total: number,
      finished: number,
      title: string
   }>()
   const [showUploadErrorList, setShowUploadErrorList] = useState(false)

   const editingMember = members.find(m => m.id === editMember)
   if(editingMember){
      console.log('editingMember', editingMember.cardNumber)
   }

   useEffect(
      () => {
         // Make sure we have a value (user has entered something in input)
         if (debouncedSearchTerm) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            fetchCurrentUsers(debouncedSearchTerm)
         } else {
            fetchCurrentUsers()
         }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm, showClaimedMembers]
   );

   useEffect(() => {
      if(csvData){
         parseUploadFile()
      }else{
         setMembers([])
         setUploadErrors([])
         setSelectedUploadKeys({})
         uploadKeysRef.current = {}
      }
   }, [csvData])

   useEffect(() => {
      parseUploadFile()
   }, [selectedUploadKeys])

   async function fetchCurrentUsers(searchText?: string, paginating?: boolean) {
      if (casinoId == null) return
      setShowLoader(true)
      try {

         console.log('Fetching Members...')

         const coll = collection(getFirestore(), `casinos/${casinoId}/promotions/${promotionId}/members`);
         const snapshot = await getCountFromServer(coll);
         setTotalCount(snapshot.data().count)

         if(promotion == null){
            const promotionResponse = await Promotions.getPromotion(casinoId, promotionId)
            if (promotionResponse == null) {
               throw new Error("Unable to find promotion");
            }
            setPromotion(promotionResponse)
         }


         let where = [] as WhereType[]
         if((searchText ?? '').length > 0){
            const searchTags = (searchText ?? '').split(' ').map((text: string) => text.toLowerCase().trim()).filter((text: string) => text.length > 0)
            where = where.concat(searchTags.map((searchTag) => {
               return {
                  fieldPath: `matches.${searchTag}`,
                  value: true,
                  opStr: '=='
               }
            }))
         }

         let orderBys = [] as OrderBy[]
         if(showClaimedMembers){
            orderBys.push({
               fieldPath: 'orderInfo.name',
               directionStr: 'desc'
            })
         }

         const response = await Members.getMembersForPromotion(
            casinoId,
            promotionId,
            {
               limit: INITIAL_PAGESIZE,
               where: where,
               lastDoc: (paginating ?? false) ? lastDoc : undefined,
               orderBys: orderBys
            },
         )

         console.log('Found', response.data.length, 'Results')

         if((paginating ?? false)){
            setMembers([...members, ...response.data])
         }else{
            setMembers(response.data)
         }

         setLastDoc(response.lastDoc)
      } catch (error) {
         console.log('Error Getting Members : ', error)
      }
      setShowLoader(false)
   }

   if (casino == null) {
      return <Progress />
   }

   return (
      <View style={{
         flex: 1,
         padding: 15
      }}>
         {uploadProgress && (
            <Progress 
               description={uploadProgress.finished === 0 ? 
                  `Preparing ${uploadProgress.total} members` : 
                  `${uploadProgress.finished}/${uploadProgress.total}.`}
               title={`Please do not close the tab. ${uploadProgress.title} in progress.`}/>
         )}
         <Modal 
            sx={{
               display: 'flex',
               flex: 1,
               justifyContent: 'center',
               alignItems: "center"
            }}
            open={editMember !== undefined}>
               <Member 
                  member={editingMember}
                  columns={columns} 
                  onUpdate={onUpdateMember}
                  onAdd={onAddMember}
                  onClose={onUpdateClose}/>
         </Modal>
         <View style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 15,
            flexDirection: 'row'
         }}>
            {isAdding === false && (
               <button>
                  <CSVReader
                     cssClass="csv-reader-input"
                     label="Upload CSV File"
                     onFileLoaded={onFileLoaded}
                     onError={onParseError}
                     parserOptions={{
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        transformHeader: (header: string) => {
                           const formatted = header.trim().replace(/\W/g, '_')
                           uploadKeysRef.current[formatted] = header
                           return formatted
                        },
                     }}
                     inputId="ObiWan"
                     inputStyle={{ opacity: 0, width: '170px', marginLeft: '-170px' }} />
               </button>
            )}
            <div>
               <input checked={showClaimedMembers} onChange={(event) => {
                  setShowClaimedMembers(event.target.checked)
               }} type='checkbox'/>&nbsp;<label>Show claimed members only</label>
            </div>
         </View>
         <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: "space-between"
         }}>
            <View style={{
               flexDirection: 'row'
            }}>
               {isAdding === true && <Icon 
                  size={40}
                  onPress={() => onPressSave()}
                  disabledStyle={{
                     opacity: 0.3,
                     backgroundColor: 'white'
                  }}
                  disabled={saveButtonStatus}
                  name='save'>
					   <SaveIcon fontSize='large'/>
				</Icon>}
               {isAdding === true && <Icon 
                  size={40}
                  onPress={onPressClear}
                  disabledStyle={{
                     opacity: 0.3,
                     backgroundColor: 'white'
                  }}
                  containerStyle={{
                     marginLeft: 10
                  }}
                  name='highlight-off'>
					<HighlightOff fontSize='large'/>
				</Icon>}
               {isAdding === false && <Icon 
                  containerStyle={{
                     marginLeft: 5
                  }}
                  size={40}
                  onPress={() => {
                     setEditMember('')
                  }}
                  name='add-circle'>
					<AddIcon fontSize='large'/>
				</Icon>}
               {isAdding === true && uploadErrors.length > 0 && <Icon 
                  containerStyle={{
                     marginLeft: 5
                  }}
                  size={40}
                  onPress={() => {
                     setShowUploadErrorList(!showUploadErrorList)
                  }}
                  color={showUploadErrorList === false ? 'red' : 'black'}
                  name='error'>
					<ErrorIcon fontSize='large'/>
				</Icon>}
            </View>
            {isAdding === false && <SearchBar
               containerStyle={{
                  backgroundColor: 'white',
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  padding: 0,
                  alignSelf: 'flex-end',
                  marginLeft: 10
               }}
               inputContainerStyle={{
                  backgroundColor: '#dddddd',
                  borderRadius: 20
               }}
               inputStyle={{
                  color: 'black',
				  width: 400,
               }}
               onClear={() => {
                  setSearchText('')
               }}
               placeholder='Search'
               onChangeText={(text) => setSearchText(text)}
               value={searchText} />}            
         </View>
         <br/>
         {showUploadErrorList === true && uploadErrors.length > 0 && <div>
            <div style={{ color: 'red' }}><b>Error in list ({uploadErrors.length})</b>: {uploadErrors.map(m => m.cardNumber ?? m.name).join(', ')}</div>
            <br/>
            <div>
               <button onClick={onPressDownloadErrorList}>Download</button> &nbsp;&nbsp;
               {validationSuccessList.length > 0 && <button onClick={onPressUploadSuccessValidation}>Save Validated Members</button>}
            </div>
            <br/><br/>
         </div>}
         {uploadKeysRef.current != null && csvData != null && <div>
            <br/>
            <b><label>Choose Columns</label></b>
            <div>
               {columns.filter((c) => (c.editable == 'always' || c.editable == 'onAdd') && (c.hidden ?? false) == false)
                  .map((column) => (
                     <div style={{ 
                        padding: '0.5em 0 0.5em 0',
                        // border: '1px solid #dddddd',
                        margin: '0.5em 0 0.5em 0',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                     }}>
                        <div style={{
                           width: 300
                        }}>{column.title}</div>    
                        {column.field != null && (
                           <select value={selectedUploadKeys[column.field]} onChange={(event) => {
                              if(column.field?.toString){
                                 setSelectedUploadKeys({
                                    ...selectedUploadKeys,
                                    [column.field?.toString()] : event.target.value
                                 })
                              }
                           }} style={{ padding: '0.1em 1em 0.1em 1em' }}> 
                              <option>--</option>
                              {Object.keys(uploadKeysRef.current).map((key) => {
                                 return (
                                    <option value={key}>{uploadKeysRef.current[key]}</option>
                                 )
                              })}
                           </select>
                        )}
                     </div>
                  ))}
            </div>
            <br/>
         </div>}
         <StylesProvider generateClassName={generateClassName}>
         <MaterialTable
            data={members}
            isLoading={showLoader}
            totalCount={members.length}
            title={isAdding === true ? 'Add Members' : 'Members List'}
            options={{
               searchFieldVariant: 'outlined',
               pageSize: PAGESIZE,
               pageSizeOptions: [PAGESIZE],
               addRowPosition: 'first',
               search: isAdding,
               searchFieldStyle:{
                  marginTop: '2em'
               }
            }}
            actions={[
               {
                  icon: 'edit',
                  onClick: (event, rowData) => {
                     const member = rowData as Members.Member
                     setEditMember(member.id)
                  }
               }
            ]}
            editable={{
               // onRowUpdate,
               // onRowAdd,
               onRowDelete,
               onBulkUpdate: onBulkUpdate,
            }}
            onChangePage={onChangePage}
            columns={(casinoId && promotion) ? [
               {
                  render: (member) => {
                     const enabled = validatePhone(member.phoneNumber ?? '')
                     return (
                        <IconButton disabled={!enabled} onClick={() => onPressSendMessage(member.id)}>
                           <Message/>
                        </IconButton>
                     )
                  }
               },
               ...getColumns(casinoId, promotion)
            ] : []} />
         </StylesProvider>
         <div style={{
            textAlign: 'right',
            fontSize: 14,
            marginTop: 3,
            color: '#11111190'
         }}>Total Members: {totalCount}</div>
         {isAdding == false && <View style={{
            marginTop: 15
         }}>
            <View />
            <button
               style={{
                  width: 200,
                  padding: 0,
               }}
               onClick={onClickDownloadTemplate}>Download Template</button>
            <br/>
            <button
               style={{
                  width: 200,
                  padding: 0,
               }}
               onClick={onPressExportMembers}>Export Members</button>
            <br/>
            <button
               style={{
                  width: 200,
                  padding: 0,
               }}
               onClick={onPressExportUpdatedMembers}>Export Updated Members</button>
            <br />
            {<CheckPermission minimumRole='admin'>
               <button style={{
                  width: 200,
                  padding: 0,
               }} onClick={onClickDeleteAll}>Delete All Members</button>
            </CheckPermission>}
         </View>}
         {isAdding === false && (
            <View>
               {uploadErrors.map((error) => (
                  <div style={{ border: '1px solid red' }}>{error}</div>
               ))}
            </View>
         )}
      </View>
   )

   async function onPressSendMessage(memberId: string){      
      try {
         const message = prompt('Send SMS Message')
         const member = members.find((m) => m.id === memberId)
         if(member && message != null){
            await Members.sendSMS(member.phoneNumber, message)
         }
      } catch (__error) {
         const error = __error as any
         console.log('Error Sending SMS', error)
         const errorResponse = error?.response?.data ?? error
         alert('Error Sending SMS' + JSON.stringify(errorResponse))
      }
   }

   function onChangePage(page: number, pageSize: number) {
      if (isAdding === false && (members.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE))) {
         if (lastDoc != null) fetchCurrentUsers(searchText, true)
      }
   }

   function onRowUpdate(newData: Members.Member) {
      return new Promise<void | Members.Member>(async (resolve, reject) => {
         setShowLoader(true)
         try {

            const memberId = newData.id
            const __members = [...members]
            const findIndex = __members.findIndex((m) => m.id === newData.id)

            const updatedMember = {} as Members.Member

            columns.filter(c => c.editable === 'always' || c.editable === 'onUpdate')
               .forEach((column) => {
                  const path = column.field?.toString()
                  if (path) {
                     const value = lodash.get(newData, path)
                     if (value != null)
                        lodash.set(updatedMember, path, value)
                  }
               })

            if(findIndex >= 0 && casinoId != null){
               if(isAdding === true){

                  const currentMember = lodash.clone({
                     ...__members[findIndex],
                     ...updatedMember
                  })
                  __members[findIndex] = currentMember
   
                  setMembers(__members)
                  resolve(currentMember)
               }else{
                  
                  updatedMember.lastUpdatedAt = firebase.firestore.Timestamp.now()
                  updatedMember.lastUpdatedBy = Users.getCurrentUserInfo()
                  
                  const response = await Members.updateMember(casinoId, promotionId, memberId, updatedMember)

                  const currentMember = lodash.clone({
                     ...__members[findIndex],
                     ...response
                  })
                  __members[findIndex] = currentMember
   
                  setMembers(__members)
                  resolve(currentMember)
               }
            }

         } catch (error) {
            Alert.alert('Error Updating Member', String(error))
            console.log('Error Updating Member : ', error)
            reject(error)
         }
         setShowLoader(false)
      })
   }

   function onRowAdd(newData: Members.Member) {
      return new Promise(async (resolve, reject) => {
         setShowLoader(true)
         try {
            const __members = [...members]
            let member = {
               enabled: promotion?.enableMemberByDefault ?? true
            } as Members.Member
            if (casinoId) {
               columns
                  .filter(c => c.editable === 'always' || c.editable === 'onAdd')
                  .forEach((column) => {
                     const path = column.field?.toString()
                     if (path) {
                        const value = lodash.get(newData, path)
                        if (value != null)
                           lodash.set(member, path, value)
                     }
                  })

               console.log('Adding Member:', member)
               const response = await Members.addMember(
                  casinoId,
                  promotionId,
                  member
               ) as Members.Member
               __members.push(response);
            }
            setMembers(__members)
            resolve(member)
         } catch (error) {
            console.log('Error Adding Member', error,)
            Alert.alert('Error Adding Member', String(error))
            reject(error)
         }
         setShowLoader(false)
      })
   }

   function onBulkUpdate(changes: Record<number, { oldData: Members.Member; newData: Members.Member }>){
      return new Promise<void>(async (resolve, reject) => {

         try {
            const changedMembers = [] as Members.Member[]
            Object.keys(changes).forEach((key) => {
               const index = Number(key)
               const value = changes[index].newData

               value.lastUpdatedAt = firebase.firestore.Timestamp.now()
               value.lastUpdatedBy = Users.getCurrentUserInfo()   

               changedMembers.push(value)               
            })          

            await onPressSave(changedMembers)
            console.log('Changed Members', changedMembers)
            resolve()  
         } catch (error) {
            Alert.alert('Error Updating Fields', String(error))  
            reject(error)
         }
      })
   }

   function onRowDelete(member: Members.Member) {
      return new Promise<void>(async (resolve, reject) => {
         try {
            const __members = [...members]
            const findIndex = __members.findIndex((m) => m.id == member.id)
            if (findIndex >= 0 && casinoId) {
               await Members.deleteMember(casinoId, promotionId, member.id)
               __members.splice(findIndex, 1)
            }
            setMembers(__members)
            resolve()
         } catch (error) {
            Alert.alert('Error Deleting Member', String(error))
            console.log('Error Deleting Member : ', error)
            reject(error)
         }
      })
   }

   async function onClickDeleteAll() {

      Alert.alert(
         'Warning',
         'This cannot be reversed. Are you sure you want to continue?',
         [
            {
               onPress: async () => {
                  setShowLoader(true)
                  try {

                     const path = `casinos/${casinoId}/promotions/${promotionId}/members`
                     const response = await Actions.readFullCollection(path, {
                        limit: 10000
                     })
                     const allMembers = response.data
                     setShowLoader(false)
                     setUploadProgress({
                        total: allMembers.length,
                        finished: 0,
                        title: 'Deleting'
                     })

                     await Actions.batchDelete(allMembers.map((m) => {
                        return {
                           path: `${path}/${m.id}`
                        }
                     }), (completed) => {
                        setUploadProgress({
                           total: allMembers.length,
                           finished: completed * 500,
                           title: 'Deleting'
                        })
                     })
                     fetchCurrentUsers()

                  } catch (error) {
                     Alert.alert('Error Deleting Records', String(error))
                  }
                  setUploadProgress(undefined)
               },
               style: 'destructive',
               text: 'Continue'
            },
            {
               style: 'cancel',
               text: 'Cancel'
            }
         ]
      )
   }

   function onPressDownloadErrorList(){
      exportListToCSV(uploadErrors, 'Upload_Error_List_' + uploadErrors.length + '_Items', [
         'firstName',
         'lastName',
         'email',
         'cardNumber',
         'phoneNumber',
         'age',
         'gender',
         'address_city',
         'address_country',
         'address_state',
         'address_stateCode',
         'address_zipCode',
         'address_street',
         'tier',
         'enabled'
      ])
   }

   function onPressUploadSuccessValidation(){
      onPressSave(validationSuccessList)
   }

   function onUpdateMember(member: Members.Member){
      console.log('Update Member', member)
      onRowUpdate(member).catch(() => null)
      setEditMember(undefined)
   }

   function onAddMember(member: Members.Member){
      console.log('Add Member', member)
      onRowAdd(member).catch(() => null)
      setEditMember(undefined)
   }

   function onUpdateClose(){
      setEditMember(undefined)
   }

   function onParseError(error: Error) {
      console.log('Error Parsing : ', error)
   }

   function onFileLoaded(data: Array<any>, fileInfo: IFileInfo) {

      const __selectedUploadKeys = {}
      columns.filter((c) => (c.editable == 'always' || c.editable == 'onAdd') && (c.hidden ?? false) == false).forEach((column) => {
         const field = column.field?.toString() ?? null
         if(field){
            const checkKey = uploadKeysRef.current[field.trim().replace('.', '_')]
            if(checkKey){
               __selectedUploadKeys[field] = checkKey
            }
         }
      })
      setSelectedUploadKeys(__selectedUploadKeys)

      setSearchText('')
      setUploadErrors([])
      setCsvData(data)
   }

   function onPressClear() {
      setMembers([])
      setUploadErrors([])
      setCsvData(undefined)
      fetchCurrentUsers()
   }

   async function onPressSave(overrideMembers?: Members.Member[]) {

      setShowLoader(true)
      setUploadErrors([])
      setValidationSuccessList([])

      try {
         
         const __members = overrideMembers ?? lodash.cloneDeep(members)
         const __uploadErrors = [] as Members.Member[]
         const __validationSuccessList = [] as Members.Member[]

         let errorCount = 0
         for (let index = 0; index < __members.length; index++) {
            const member = __members[index];
            delete member.tableData
            let isValid = true
            columns.forEach((column) => {
               if(Boolean(column.validate?.(member) ?? true) === false){
                  isValid = false
               }
            })
            if((isValid as boolean) === false){
               errorCount = errorCount + 1
               __uploadErrors.push(member)
            }else{
               __validationSuccessList.push(member)
            }
         }

         setUploadErrors(__uploadErrors)
         setValidationSuccessList(__validationSuccessList)

         if (errorCount > 0)
            throw new Error("There are a few errors with some of the fields that are marked in red. Please fix.");

         setUploadProgress({
            finished: 0,
            total: __members?.length ?? 0,
            title: 'Upload'
         })

         if (casinoId) {
            const response = await Members.uploadMembersForPromotion(
               casinoId, 
               promotionId, 
               __members,
               (completed) => {
                  setUploadProgress({
                     total: __members?.length ?? 0,
                     finished: completed * 500,
                     title: 'Upload'
                  })
               }
            )
            console.log('Upload Response', response)
            history.push(`/promotions/${promotionId}`)
         } else {
            throw new Error("Casino id not found");
         }

      } catch (error) {
         Alert.alert('Error Uploading Members', String(error))
         console.log('Error Uploading Members', error)
      }
      setShowLoader(false)
      setUploadProgress(undefined)
   }

   function onClickDownloadTemplate() {

      const templateFile = [{
         firstName: 'John',
         lastName: 'Appleseed',
         email: 'john.appleseed@example.com',
         cardNumber: '1234567890',
         phoneNumber: '123456789',
         age: 70,
         gender: 'male',
         address: {
            city: 'New York City',
            country: 'United States',
            stateCode: 'NY',
            zipCode: '10001',
            street: '16th Avenue'
         },
         tier: 1,
         enabled: true
      } as Members.Member]
      exportListToCSV(templateFile, 'Members_Upload_Template')
   }

   async function onPressExportUpdatedMembers() {

      setShowLoader(true)
      try {
         const response = await Members.getAllMembersForPromotion(casinoId!, promotionId, {
            limit: 10000,
            orderBy: {
               fieldPath: 'lastUpdatedAt',
               directionStr: 'desc'
            }
         })
         exportListToCSV(response.data, 'Members_' + response.data.length, [
            ...[
               'firstName',
               'lastName',
               'email',
               'cardNumber',
               'phoneNumber',
               'age',
               'gender',
               'address_city',
               'address_country',
               'address_state',
               'address_stateCode',
               'address_zipCode',
               'address_street',
               'tier',
               'enabled',
               'orderInfo_name'
            ], ...((promotion?.enableMemberByDefault ?? true) ? [] : ['enabled'])
         ])  
      } catch (error) {
         Alert.alert('Error Exporting Members' + String(error))
      }
      setShowLoader(false)
   }

   async function onPressExportMembers() {

      setShowLoader(true)
      try {
         const response = await Members.getAllMembersForPromotion(casinoId!, promotionId, {
            limit: 10000
         })
		 const members = response.data.map((member) => {
            const phoneNumber = member.phoneNumber ?? ''
            return {
				...member,
				phoneNumber: phoneNumber.substring(phoneNumber.length - 10)
            }
         })
         exportListToCSV(members, 'Members_' + members.length, [...[
            'firstName',
            'lastName',
            'email',
            'cardNumber',
            'phoneNumber',
            'age',
            'gender',
            'address_city',
            'address_country',
            'address_state',
            'address_stateCode',
            'address_zipCode',
            'address_street',
            'tier',
            'orderInfo_name'
         ], ...((promotion?.enableMemberByDefault ?? true) ? [] : ['enabled'])])  
      } catch (error) {
         Alert.alert('Error Exporting Members' + String(error))
      }
      setShowLoader(false)
   }

   function parseUploadFile() {

      console.log('Parsing File...', selectedUploadKeys, uploadKeysRef.current)
      try {
         const __members: Members.Member[] = []
         csvData?.forEach((member) => {
            let newMember = {
               casinoInfo
            } as Members.Member
            columns.forEach((column) => {
               const path = column.field as string
               const mappedKey = selectedUploadKeys[path]
               let value = lodash.get(member, mappedKey) ?? column.initialEditValue
                              
               const emptyFunction = column.emptyValue as (member: Members.Member) => any
               if (emptyFunction != null){
                  value = emptyFunction(newMember)
               }

               value = value != null ? convertToTypeOf(column.type, value) : null

               const formatFunction = column.formatValue as (value: any) => any
               if (formatFunction != null){
                  console.log('Column', column.field)
                  value = formatFunction(value)
                  console.log('Value', value, '*****')
               }

               lodash.set(newMember, path, value)

            })
            if(newMember.id == null){
               newMember.id = Actions.generateIDForPath('members')
            }
            __members.push(newMember)
         })
         setMembers(__members)
      } catch (error) {
         Alert.alert('Error Loading Form', String(error))
         console.log('Error loading form: ', error)
      }
   }
}

export function formatPhoneNumber(phoneNumber: string) {
   const formatted = phoneNumber.replace(/[^0-9]/g, "")
   if (formatted?.length === 10 && validatePhone(formatted) === false) {
      return `+1${formatted}`
   }
   return phoneNumber
}
