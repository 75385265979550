import React, { useEffect, useState } from "react";
import { Members } from "cs-connect";
import {
    Text,
    View
} from 'react-native'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import Button from './components/Button'
import { CustomColumn, StateOptions } from "./MembersList";
import { convertToTypeOf } from "./utils";

const panelSize = {
    width: window.innerWidth - 100,
    height: window.innerHeight - 100
}

export default function Member(props: {
    member?: Members.Member
    onClose: () => void;
    columns: CustomColumn[];
    onUpdate: (member: Members.Member) => void;
    onAdd: (member: Members.Member) => void;
}) {

    const { columns, onClose, onUpdate, onAdd } = props;

    const [member, setMember] = useState<Members.Member>(cloneDeep(props.member ?? {}) as Members.Member)
    const [isUpdate] = useState(props.member !== undefined)
    const [errorFields, setErrorFields] = useState<{[key: string] : boolean}>({})
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    useEffect(() => {
        if(props.member == null){
            const __member = {} as Members.Member
            columns.forEach((c) => {
                if(c.initialEditValue){
                    set(__member, c.field as  string, c.initialEditValue)
                }
            })
            onChangeValues(__member)
        }
    }, [])

    useEffect(() => {
        console.log('Member Changes', member)
    }, [member])

    useEffect(() => {
        console.log('errorFields', errorFields)
    }, [errorFields])

    return (
        <View style={{
            backgroundColor: 'white',
            height: panelSize.height,
            width: panelSize.width,
            alignSelf: 'center',
            overflow: 'hidden',
            borderRadius: 5,
        }}>
            <View style={{
                height: 60,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#dddddd'
            }}>
                <h2>{props.member == null ? 'Add Member' : 'Edit Member'}</h2>
            </View>
            <View style={{
                flex: 1,
                padding: '1em',
                paddingHorizontal: '3em',
                flexDirection: 'column',
                flexWrap: 'wrap',
                overflow: "scroll",
                alignItems: 'flex-start'
            }}>
                {columns.map((c, index) => {

                    const disabled = (c.editable === 'never') ? true : 
                                     (c.editable === 'always' ? false : (c.editable === 'onAdd') ? isUpdate : false)

                    const emptyValue = c.emptyValue as (member: Members.Member) => any
                    const lookup = c.lookup as {[key: string] : any}
                    const isValid = errorFields[c.field as string] ?? true

                    const hidden = c.hidden ?? false
                    if(hidden === true){
                        return <View/>
                    }

                    return (
                        <View style={{
                            marginVertical: '1em',
                            width: panelSize.width/3.5
                        }}>
                            <b><label>{c.title}</label></b>
                            {(c.render != null && c.editable === 'never') ? c.render(member, 'row') : (
                                <View style={{
                                    flex: 1,
                                }}>
                                    {(c.type === 'string' || c.type === 'numeric') && (
                                        <div style={{
                                            display: 'flex',
                                            flex: 1,
                                        }}>
                                            {lookup == null ? (
                                                <input 
                                                    placeholder={c.editPlaceholder}
                                                    name={c.field as string}
                                                    value={get(member, c.field as string) ?? emptyValue?.(member) ?? ''}
                                                    onChange={(event) => {
                                                        let value = event.target.value
                                                        onChangeValue(event.target.name, value)
                                                    }}
                                                    onBlur={(event) => {
                                                        const value = event.target.value
                                                        const formatted = c.formatValue?.(value) ?? value
                                                        onChangeValue(c.field as string, convertToTypeOf(c.type, formatted))
                                                    }}
                                                    disabled={disabled}
                                                    style={{
                                                        height: 50,
                                                        marginTop: 5,
                                                        paddingLeft: '1em',
                                                        fontSize: 16,
                                                        flex: 1,
                                                        border: `1px solid ${isValid === false ? 'red' : '#dddddd'}`
                                                    }}/>
                                            ) : (
                                                <select 
                                                    style={{
                                                        marginTop: 5,
                                                        padding: '1em',
                                                        fontSize: 16,
                                                        flex: 1,
                                                        border: `1px solid ${isValid === false ? 'red' : '#dddddd'}`
                                                    }}
                                                    onChange={(event) => {
                                                        if(event.target.name === 'address.stateCode'){                                                            
                                                            onChangeValues({
                                                                address: {
                                                                    ...member.address,
                                                                    state: StateOptions[event.target.value],
                                                                    stateCode: event.target.value
                                                                }
                                                            })
                                                        }else{
                                                            onChangeValue(event.target.name, event.target.value)
                                                        }
                                                    }}
                                                    value={get(member, c.field as string) ?? emptyValue?.(member) ?? ''}
                                                    name={c.field as string}
                                                    id={c.field as string}>
                                                        {Object.keys((lookup ?? {})).map((key) => (
                                                            <option value={key}>{(lookup ?? {})[key]}</option>
                                                        ))}
                                                </select>
                                            )}
                                        </div>
                                    )}
                                    {c.type === 'boolean' && (
                                        <input 
                                            type={'checkbox'}
                                            name={c.field as string}
                                            placeholder={c.editPlaceholder}
                                            checked={get(member, c.field as string)}
                                            onChange={(event) => {
                                                onChangeValue(event.target.name, event.target.checked)
                                            }}
                                            disabled={disabled}
                                            style={{
                                                display: 'flex',
                                                alignSelf: 'start',
                                                marginTop: 20,
                                                paddingLeft: '1em',
                                                fontSize: 16
                                            }}/>
                                    )}
                                </View>
                            )}
                        </View>
                    )
                })}
            </View>
            {showErrorMessage && Object.keys(errorFields).length > 0 && <Text style={{
                textAlign: 'center',
                color: 'red'
            }}>There seems to be errors in the form. Please fix them to save the member.</Text>}
            <View style={{
                flexDirection: 'row',
                justifyContent: 'center'
            }}>
                <Button 
                    type='outlined'
                    buttonStyle={{
                        width: 200,
                        borderRadius: 5
                    }}
                    onPress={() => onClose()}
                    containerStyle={{ margin: '1em' }} 
                    title='Cancel'/>
                <Button                     
                    buttonStyle={{
                        width: 200,
                        borderRadius: 5
                    }}
                    onPress={onPressSave}
                    containerStyle={{ margin: '1em' }} 
                    title='Save'/>
            </View>
        </View>
    )

    function onPressSave(){

        const __errorFields = {...errorFields}
        columns.forEach((c) => {
            const isValid = c.validate?.(member) ?? true
            if(isValid){
                delete __errorFields[c.field as string]
            }else{
                __errorFields[c.field as string] = false
            }
        })
        setErrorFields(__errorFields)

        console.log('Error Fields', __errorFields, member)

        if (Object.keys(__errorFields).length > 0) {
            setShowErrorMessage(true)
        } else {
            props.member ? onUpdate(member) : onAdd(member)
        }
    }

    function onChangeValue(path: string, value: any){
        const __member = {...member}
        set(__member, path, value)
        setMember(__member)
    }

    function onChangeValues(values: Partial<Members.Member>){
        const __member = {...member}
        setMember({
            ...__member,
            ...values
        })
    }
}
