import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Progress(props:{
   title?: string,
   description?: string
}) {
   return (
      <Backdrop
         sx={{ color: '#fff', zIndex: 100, display: 'flex', flexDirection: 'column', background: '#11111199' }}
         open={true}>
         <CircularProgress />
         <div style={{
            textAlign: 'center'
         }}>
            {props.title != null && <h3>{props.title}</h3>}
            {props.description != null && <p>{props.description}</p>}
         </div>
      </Backdrop>
   )
}
