import React, { useContext, useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import MaterialTable, { Column } from 'material-table'
import {
	Promotions
} from 'cs-connect';
import { Alert } from './utils/Alert.web';
import { StatusOptions } from 'cs-connect/lib/Promotions';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import firebase from 'firebase/compat/app';
import { Link } from 'react-router-dom';
import ArrowRight from '@material-ui/icons/ArrowRight'
import FileCopy from '@material-ui/icons/FileCopy'
import { renderStatus } from './CreatePromotion';
import CheckPermission, { isAuthorized } from './utils/CheckPermission';
import { RoleContext } from './App';
import moment from 'moment'
import { usePromotionsContext } from './containers/PromotionsContext';

const INITIAL_PAGESIZE = 150
const PAGESIZE = 10

const DATE_TIME_FORMAT = 'MMM DD, YYYY h:mm A'

const generateClassName = createGenerateClassName({
	productionPrefix: 'mt',
	seed: 'mt'
}); //To prevent material table from changing appbar style

const getColumns = (): Column<Promotions.Promotion>[] => {
	return [
		{
			title: 'Name',
			editable: 'always',
			render: (casino) => (
				<div>
					<div style={{ fontSize: 10 }}>{casino.id}</div>
					<label>{casino.name}</label>
				</div>
			)
		},
		{
			title: 'Start Date',
			render: (promotion) => {
				const startDate = promotion.startDate as firebase.firestore.Timestamp
				return (
					<Text>{startDate?.toDate ? moment(startDate.toDate()).format(DATE_TIME_FORMAT) : '--'}</Text>
				)
			},
			editable: 'never'
		},
		{
			title: 'End Date',
			render: (promotion) => {
				const endDate = promotion.endDate as firebase.firestore.Timestamp
				return (
					<Text>{endDate?.toDate ? moment(endDate.toDate()).format(DATE_TIME_FORMAT) : '--'}</Text>
				)
			},
			editable: 'never'
		},
		{
			title: 'Claims',
			editable: 'never',
			render: (promotion) => (
				<Text>{(promotion.totalClaims ?? '--')} / {promotion.limitClaims ?? '--'}</Text>
			),
		},
		{
			title: 'Updated On',
			render: (promotion) => (
				<Text>{moment(promotion.updatedAt.toDate()).format(DATE_TIME_FORMAT)}</Text>
			),
			editable: 'never'
		},
		{
			title: 'Created On',
			render: (promotion) => (
				<Text>{moment(promotion.createdAt.toDate()).format(DATE_TIME_FORMAT)}</Text>
			),
			editable: 'never'
		},
		{
			title: 'Status',
			field: 'status',
			lookup: StatusOptions,
			editable: 'never',
			render: (promotion) => renderStatus(promotion),
		},
		{
			title: '',
			render: (promotion) => (
				<div style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<CheckPermission minimumRole='admin'>
						<Link to={`promotions/create?clone=${promotion.id}`} style={{
							textDecoration: 'none',
						}}>
							<FileCopy fontSize='medium' htmlColor='black'/>
						</Link>
					</CheckPermission>
					&nbsp;&nbsp;&nbsp;
					<Link to={`promotions/${promotion.id}`} style={{
						textDecoration: 'none',
					}}>
						<ArrowRight fontSize='large' htmlColor='black'/>
					</Link>
				</div>
			),
			editable: 'never'
		}
	]
}

function PromotionsList() {

	const { promotions, fetchPromotions, deletePromotion } = usePromotionsContext()
	const [columns ] = useState(getColumns())
	const roleContext = useContext(RoleContext)
	const [showLoader, setShowLoader] = useState(false)

	useEffect(() => {
		getPromotions()
	}, [])

	async function getPromotions() {
		setShowLoader(true)
		try {
			await fetchPromotions()
		} catch (error) {
			Alert.alert('Error Fetching Promotions : ', String(error))
			console.log('Error Fetching Promotions : ', error)
		}
		setShowLoader(false)
	}

	return (
		<View style={{
			padding: 15
		}}>
			<View style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				marginBottom: 25,
				flex: 1
			}}>
				<Text />
				<Link style={{
					textDecoration: 'none'
				}} to={'/promotions/create'}>
					<Text style={{
						paddingHorizontal: 30,
						paddingVertical: 10,
						borderWidth: 1,
						borderColor: '#dddddd'
					}}>Create</Text>
				</Link>
			</View>
			<StylesProvider generateClassName={generateClassName}>
				<MaterialTable
					editable={{
						onRowDelete: isAuthorized('admin',roleContext.role) ? onRowDelete : undefined
					}}
					isLoading={showLoader}
					options={{
						search: false,
						pageSizeOptions: [PAGESIZE],
						pageSize: PAGESIZE,
						sorting: true,
						filtering: false,
					}}
					title='Promotions'
					columns={columns}
					data={promotions} />
			</StylesProvider>
		</View>
	)

	function onRowDelete(promotion: Promotions.Promotion) {
		return new Promise<void>(async (resolve, reject) => {

			setShowLoader(true)
			try {
				await deletePromotion(promotion.id)
				resolve()
			} catch (error) {

				reject(error)
				Alert.alert(
					'Error Deleting Promotion',
					String(error)
				)
			}
			setShowLoader(false)
		})
	}

	function onChangePage(page: number, pageSize: number) {
		if (promotions.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {

		}
	}

}

export default PromotionsList