import React, { useEffect, useState } from 'react'
import { View, Text, Image, FlatList } from 'react-native'
import {
   Casinos,
   Gifts,
   Promotions
} from 'cs-connect'
import Button from './components/Button'
import Icon from './components/Icon'
import Grid from '@mui/material/Grid';
import {
   useSelector,
   useDispatch
} from 'react-redux'
import { AppState } from './store';
import Progress from './utils/Progress';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from './utils/Alert.web';
import {
	usePromotionsContext
} from './containers/PromotionsContext'
import ArrowUpIcon from '@material-ui/icons/ArrowUpwardOutlined'
import ArrowDownIcon from '@material-ui/icons/ArrowDownwardOutlined'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded'
import { imageForProduct } from './utils'
// import ButtonBase from '@material-ui/core/ButtonBase'

export default function SelectGifts() {

   const history = useHistory()
   const params = useParams() as {
      promotionId: string
   }

   const [availableProducts, setAvailableProducts] = useState<Gifts.ShopifyProduct[]>([])
   const [selectedProducts, setSelectedProducts] = useState<Gifts.ShopifyProduct[]>([]);
   const { updatePromotion, fetchPromotion } = usePromotionsContext()

   const [showLoader, setShowLoader] = useState(false)

   const casino = useSelector((state:AppState) => state.casino) as Casinos.Casino
   
   const collectionId = casino?.collectionId
   const promotionId = params.promotionId

	const [promotion, setPromotion] = useState<Promotions.Promotion>()
   const productIds = promotion?.productIds ?? []
   
   const selectedIds = selectedProducts.map(p => p.id) ?? []
   const [productTiers, setProductTiers] = useState<{[key: number]: number}>(promotion?.productTiers ?? {})

	useEffect(() => {
		if (promotion) {
			fetchGifts()
		}
	}, [promotion])

	useEffect(() => {

		setShowLoader(true)
		fetchPromotion(promotionId)
			.then((response) => {
				setPromotion(response)
				setShowLoader(false)
			})
			.catch(() => {
				setShowLoader(false)
			})
	}, [])

   async function fetchGifts(){
      if(collectionId == null) return
      setShowLoader(true)
      try {
         const availableResponse = await Gifts.getGiftsForCasino(collectionId)
         setAvailableProducts(availableResponse)

         const selectedResponse = await Gifts.getGiftsWithProductIds(productIds, true)
         setSelectedProducts(selectedResponse)

      } catch (error) {
         console.log(
            'Error Fetching Gifts',
            String(error)
         )
      }
      setShowLoader(false)
   }

   return (
      <Grid xs={12} sm={10} md={10} lg={10} xl={10}>
         <View style={{
            padding: 15
         }}>
            <View style={{
               flexDirection: 'row',
               justifyContent: 'space-between',
               alignItems: 'center',
               marginBottom: 10
            }}>
               <Text style={{
                  fontSize: 22,
               }}>Select Gifts</Text>
               <Button 
                  onPress={onPressSave}
                  disabled={Object.keys(selectedIds).length == 0}
                  title='SAVE'/>
            </View>
            {showLoader == true && <Progress />}
            <View style={{
               flexDirection: 'row',
               flexWrap: 'wrap'
            }}>
               {/* {availableProducts.map((item) => {
                  const images = item.images
                  const thumbnail = imageForProduct(gift)
                  const productId = item.id
                  const checked = selectedIds.find(p => p == productId)
                  return (
                     <View style={{
                        height: '18em',
                        width: '15em',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '1em',
                     }}>
                        <View style={{
                           flex: 1,
                           borderWidth: checked ? 2 : 0.1,
                           borderColor: checked ? '#224ed4' : '#dddddd',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           overflow: 'hidden',
                           padding: '1em',
                           borderRadius: 5,
                           backgroundColor: '#dddddd20',
                        }}>
                           <ButtonBase onClick={() => {
                              if (checked == null) {
                                 onPressAdd(item)
                              } else {
                                 onPressRemove(item)
                              }
                           }} style={{
                              position: 'absolute',
                              top: 10,
                              right: 10
                           }}>
                              {checked ? (
                                 <CheckBoxOutlined />
                              ) : (
                                 <CheckBoxOutlineBlank />
                              )}
                           </ButtonBase>                     
                           <Image style={{
                              width: 100,
                              height: '100%',
                              flex: 1,
                              resizeMode: 'contain',
                           }} source={{
                              uri: thumbnail
                           }} />
                           <View style={{
                              flex: 0.5,
                              marginTop: '1em'
                           }}>
                              <b>{item.title}</b>
                              <Text numberOfLines={3} style={{
                                 marginTop: '0.1em',
                                 fontSize: 12,
                              }}>{item.description}</Text>
                           </View>
                        </View>
                     </View>
                  )
               })} */}
               <FlatList
                  ListHeaderComponent={() => (
                     <Text style={{
                        marginVertical: 10,
                        fontSize: 18
                     }}>Available Gifts</Text>
                  )}
                  style={{
                     width: 300
                  }}
                  renderItem={renderAvailableProductItem}
                  data={availableProducts} />
               <View style={{
                  width: 50,
                  flex:1,
                  padding: 25,
                  justifyContent: 'center'
               }}>
               </View>
               <FlatList
                  ListHeaderComponent={() => (
                     <Text style={{
                        marginVertical: 10,
                        fontSize: 18
                     }}>Selected Gifts</Text>
                  )}
                  style={{
                     width: 300
                  }}
                  renderItem={renderSelectedProductItem}
                  data={selectedProducts} />
            </View>
         </View>
      </Grid>
   )

   function renderProduct({ item, index }: { item: Gifts.ShopifyProduct, index: number }, type: 'add' | 'delete') {

		const thumbnail = imageForProduct(item)
      const productId = item.id
      const checked = selectedIds.find(p => p === productId)
      return (
         <div style={{
            display: 'flex',
            padding: 15,
            flexDirection: 'row',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: '#dddddd',
            justifyContent: 'space-between',
            marginBottom: 15,
            border: '1px solid #dddddd'
         }}>
            {type == 'delete' && (
               <View style={{
                  marginRight: 20,
               }}>
                  <Icon disabled={index == 0} onPress={() => onMove(index, 'up')} disabledStyle={{
                     backgroundColor: 'white',
                     opacity: 0.3
                  }}>
						<ArrowUpIcon htmlColor='gray'/>
				  </Icon>
                  <Icon disabled={index == selectedProducts.length - 1} onPress={() => onMove(index, 'down')} disabledStyle={{
                     backgroundColor: 'white',
                     opacity: 0.3
                  }} >
						<ArrowDownIcon htmlColor='gray'/>
				  </Icon>
               </View>
            )}
            <View style={{
               flexDirection: 'row',
               alignItems: 'center',
               flex: 1
            }}>
               <Image
                  style={{
                     width: 40,
                     height: 40,
                     marginRight: 10
                  }}
                  source={{
                     uri: thumbnail,
                     cache: 'force-cache'
                  }} />
               <Text style={{
                  fontSize: 15
               }}>{item.title}</Text>
            </View>
            <View style={{
               flexDirection: 'row',
               paddingLeft: 10
            }}>
               {type === 'delete' && (promotion?.allowTiers ?? false) && (
                  <input
                     style={{
                        marginRight: 10,
                        width: 80,
                        textAlign: 'center',
                        fontSize: 20,
                        borderRadius: 5,
                        border: '1px solid #dddddd'
                     }}
                     value={productTiers[item.id]}
                     onChange={(e) => {
                        setProductTiers({
                           ...productTiers,
                           [productId]: Number(e.target.value)
                        })
                     }}
                     type={'number'} />
               )}
               <Icon
                  onPress={() => {
                     if (type == 'add') {
                        onPressAdd(item)
                     } else {
                        onPressRemove(item)
                     }
                  }}
                  style={{
                     justifyContent: 'center',
                     alignItems: 'center',
                     borderWidth: 1,
                     borderColor: '#dddddd',
                     borderRadius: 25					 
                  }}
                  name={type === 'add' ? (checked != null ? 'check' : 'add') : type}>
					  {type === 'add' && (
						  checked != null ? (
							  <CheckIcon fontSize='large'/>
						  ) : (
							  <AddIcon fontSize='large'/>
						  )
					  )}
					  {type === 'delete' && <DeleteIcon fontSize='large'/>}
				  </Icon>
            </View>
         </div>
      )
   }

   function onMove(currentIndex, direction: 'up' | 'down'){

      function array_move(arr, old_index, new_index) {
         if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
               arr.push(undefined);
            }
         }
         arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
         return arr; // for testing
      };

      const __selectedProducts = [...selectedProducts]
      array_move(__selectedProducts, currentIndex, direction == 'up' ? (currentIndex - 1) : (currentIndex + 1))
      setSelectedProducts(__selectedProducts)
   }

   function renderSelectedProductItem(row) {
      return renderProduct(row,'delete')
   }

   function renderAvailableProductItem(row) {
      return renderProduct(row,'add')
   }

   function onPressRemove(item: Gifts.ShopifyProduct) {

      const __selectedProducts = [...selectedProducts]
      const findIndex = __selectedProducts.findIndex(s => s.id == item.id)
      if (findIndex >= 0) {
         __selectedProducts.splice(findIndex, 1)
      }
      if (promotion?.allowTiers ?? false) {
         const __productTiers = { ...productTiers }
         delete __productTiers[item.id]
         setProductTiers(__productTiers)
      }
      setSelectedProducts(__selectedProducts)
   }

   function onPressAdd(item) {
      const __selectedProducts = [...selectedProducts]
      const findIndex = __selectedProducts.findIndex(s => s.id == item.id)
      if (findIndex < 0) {
         __selectedProducts.push(item)
      }
      if(promotion?.allowTiers ?? false){
         setProductTiers({
            ...productTiers,
            [item.id]: 1
         })
      }
      setSelectedProducts(__selectedProducts)
   }

   async function onPressSave() {

      setShowLoader(true)
      try {
         await updatePromotion(promotionId, {
            productIds: selectedIds,
            productTiers: productTiers
         })
         history.push(`/promotions/${promotionId}`)
      } catch (error) {
         console.log('Error Saving Gifts')
         Alert.alert(
            'Error Saving Gifts',
            String(error)
         )
      }
      setShowLoader(false)
   }
}
