import React from 'react'
import { View, Text } from 'react-native'

export default function Users() {
   return (
      <View>
         <Text>Users</Text>
      </View>
   )
}
