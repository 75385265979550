import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import TextField from './utils/TextField';
import Button from './components/Button'
import { Link } from 'react-router-dom';
import Check from '@material-ui/icons/CheckCircleOutline'
import { Alert } from './utils/Alert.web';
import firebase from 'firebase/compat/app';
import Progress from './utils/Progress';

export default function ResetPassword() {

    const [email, setEmail] = useState('')
    const [resetSuccess, setResetSuccess] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    if(showLoader === true){
        return <Progress/>
    }

    return (
        <Grid container md={6} style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignSelf: 'center',
            padding: '1em',
            paddingTop: '20%'
        }}>
            {resetSuccess === false ? (
                <div style={{
                    textAlign: 'center',
                    border: '1px solid #dddddd',
                    borderRadius: '0.3em',
                    padding: '2em 5em 5em 5em'
                }}>
                    <h3 style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                    }}>Trouble Logging In?</h3>
                    <p>Enter your username or email address and we'll send you a link to get back into your account.</p>
                    <TextField
                        label='Email'
                        value={email}
                        placeholder='Enter your email'
                        onChangeText={(value) => setEmail(value.trim())} />
                    <br />
                    <Button
                        onPress={onPressResetLink}
                        titleStyle={{
                            fontWeight: 'bold',
                            fontSize: 15
                        }}
                        buttonStyle={{
                            borderRadius: '0.5em',
                            height: 40
                        }}
                        title='Send Reset Link' />
                </div>
            ) : (
                <div style={{
                    textAlign: 'center',
                    border: '1px solid #dddddd',
                    borderRadius: '0.3em',
                    padding: '2em 5em 4em 5em'
                }}>
                    <Check style={{
                        fontSize: 100,
                        color: 'green'
                    }} />
                    <h3 style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                    }}>Password Link Sent</h3>
                    <p>A password reset link has been sent to your email id. You can click on it to reset your password.</p>
                </div>
            )}
            <Link style={{
                width: '100%',
            }} to='/'><button style={{
                height: 50,
                width: '100%',
                marginTop: '-5px'
            }}>Back to login</button></Link>
        </Grid>
    )

    async function onPressResetLink() {

        setShowLoader(true)
        try {
            if (email.length < 5) {
                throw new Error("Please enter a valid email address for the account you want to reset.");
            }
            await firebase.auth().sendPasswordResetEmail(email)
            setResetSuccess(true)
        } catch (error: any) {
            console.log('Error Resetting Password: ', error)
            Alert.alert('Error Resetting Password', error.message)
        }
        setShowLoader(false)
        
    }
}
