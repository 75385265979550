import { View, Text } from 'react-native'
import React, { useEffect, useMemo, useState } from 'react'
import MaterialTable, { Column } from 'material-table';
import {
   StylesProvider,
   createGenerateClassName
} from '@material-ui/styles';
import {
   Casinos,
   Promotions,
   Vouchers
} from 'cs-connect'
import {
   useParams
} from 'react-router-dom'
import { Alert } from './utils/Alert.web';
import {
   LastDocType
} from 'firestar'
import lodash from 'lodash';
import { SectionPanel } from './utils/SectionPanel';
import {
   useSelector
} from 'react-redux'
import { AppState } from './store';
import {
   getDiff,
   flattenObject
} from 'firestar/lib/Utils'

var QRCode = require('qrcode.react');

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

const generateClassName = createGenerateClassName({
   productionPrefix: 'mt',
   seed: 'mt'
}); //To prevent material table from changing appbar style

export default function VouchersList() {

   const [vouchers, setVouchers] = useState<Vouchers.Voucher[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const [lastDoc, setLastDoc] = useState<LastDocType>()
   const casino = useSelector((state:AppState) => state.casino) as Casinos.Casino
   const [promotion, setPromotion] = useState<Promotions.Promotion>()

   const { promotionId } = useParams() as {
      promotionId: string
   }

   useEffect(() => {
      if(promotion != null){
         fetchVouchers()
      }
   }, [promotion])

   useEffect(() => {
      fetchPromotion()
   }, [])

   const columns = useMemo<Column<Vouchers.Voucher>[]>(() => [
      {
         field: 'id',
         title: 'Id',
         editable: 'never'
      },
      {
         field: 'name',
         title: 'Name',
         editable: 'always'
      }
   ], [])

   return (
      <View style={{
         padding: 20
      }}>
         <Text style={{
            marginBottom: 15
         }}>{casino?.name ?? '•••'} / {promotion?.name ?? '•••'}</Text>
         <StylesProvider generateClassName={generateClassName}>
            <MaterialTable
               isLoading={showLoader}
               options={{
                  search: false,
                  pageSizeOptions: [PAGESIZE],
                  pageSize: PAGESIZE,
                  sorting: true,
                  filtering: false,
               }}
               detailPanel={renderDetail}
               editable={{
                  onRowAdd: onRowAdd,
                  onRowUpdate: onRowUpdate,
                  onRowDelete: onRowDelete
               }}
               columns={columns}
               data={vouchers}
               title='Vouchers' />
         </StylesProvider>
      </View>
   )

   async function fetchPromotion(){
      setShowLoader(true)
      try {
         const response = await Promotions.getPromotionWithId(promotionId)
         if(response != null){
            setPromotion(response)
         }else{
            throw new Error("We could not find this promotion");  
         }
      } catch (error) {
         console.log('Error Getting Promotion',error)
         Alert.alert('Error Getting Promotion ',String(error))
      }
      setShowLoader(false)
   }

   async function fetchVouchers() {
      setShowLoader(true)
      try {
         const response = await Vouchers.getVoucherForPromotion(
            casino?.id!,
            promotionId,
            {
               limit: INITIAL_PAGESIZE,
               lastDoc: lastDoc,
               orderBy: {
                  fieldPath: 'createdAt',
                  directionStr: 'desc'
               }
            }
         )
         setLastDoc(response.lastDoc)
         setVouchers(response.data)
      } catch (error) {
         console.log('Error Getting Vouchers', error)
         Alert.alert('Error Getting Vouchers', String(error))
      }
      setShowLoader(false)
   }

   function onRowAdd(newData: Vouchers.Voucher) {
      return new Promise<Partial<Vouchers.Voucher>>(async (resolve, reject) => {
         try {

            if (casino == null || promotion == null) {
               throw new Error("Casino and Promotion could not found");
            }

            const newVoucher = {
               casinoInfo: Casinos.getCasinoInfo(casino),
               promotionInfo: Promotions.getPromotionInfo(promotion)
            } as Vouchers.Voucher

            columns.filter(
               (column) => (column.editable == 'always' || column.editable == 'onAdd')
            ).forEach((column) => {
               const value = lodash.get(newData, column.field ?? '')
               lodash.set(newVoucher, (column.field ?? ''), value)
            })

            const response = await Vouchers.createVoucher(casino?.id!, promotionId, newVoucher)

            const __vouchers = [...vouchers]
            __vouchers.push(response as Vouchers.Voucher)
            setVouchers(__vouchers)

            resolve(response)
         } catch (error) {

            Alert.alert('Error Creating A voucher', String(error))
            reject(error)
         }
      })
   }

   function onRowUpdate(newData: Vouchers.Voucher) {
      return new Promise<void>(async (resolve, reject) => {
         try {

            const __vouchers = [...vouchers]
            const changedData = {} as Vouchers.Voucher
            let oldData : undefined | Vouchers.Voucher

            const findIndex = vouchers.findIndex((v) => v.id == newData.id)
            if(findIndex >= 0){
               oldData = __vouchers[findIndex]
            }
            columns.filter(m => m.editable == 'always' || m.editable == 'onUpdate')
            .forEach(m => {
               lodash.set(changedData, m.field ?? '', lodash.get(newData, m.field ?? '') ?? null)
            })

				const changes = getDiff(changedData, oldData)
            delete (changes as any).tableData;

            const flattenedChanges = flattenObject(changes)
            console.log('flattenedChanges', flattenedChanges)

            await Vouchers.update(casino.id, promotionId, newData.id, flattenedChanges)

            __vouchers[findIndex] = {
               ...oldData,
               ...changedData
            }
            setVouchers(__vouchers)
            resolve()
         } catch (error) {
            alert('Error Deleting Voucher' + String(error))
            reject(error)
         }
      })
   }

   function onRowDelete(newData: Vouchers.Voucher) {
      return new Promise<void>(async (resolve, reject) => {
         try {
            await Vouchers.deleteVoucher(casino.id, promotionId, newData.id)
            const findIndex = vouchers.findIndex((v) => v.id == newData.id)
            if(findIndex >= 0){
               const __vouchers = [...vouchers]
               __vouchers.splice(findIndex, 1)
               setVouchers(__vouchers)
            }
            resolve()
         } catch (error) {
            alert('Error Deleting Voucher' + String(error))
            reject(error)
         }
      })
   }

   function renderDetail(newData: Vouchers.Voucher) {
      return (
         <View style={{
            padding: 15
         }}>
            <SectionPanel
               title='QR Image'
               render={() => (
                  <View>
                     <QRCode value={'https://app.infigifts.com/vouchers/' + newData?.id} />
                  </View>
               )} />
         </View>
      )
   }
}