import { Change, AppState } from '.'
import {
   Promotions
} from 'cs-connect'

const initialState: Promotions.Promotion[] = []

export default function promotions(state = initialState, action: Change) {
   switch (action.type) {
      case 'SYNC_PROMOTIONS': {

         let currentList = [...state]            
         const newPromotions = [...action.payload as Promotions.Promotion[]]
         newPromotions.forEach( (p:Promotions.Promotion) => {
               const findIndex = currentList.findIndex( (c) => c.id ===  p.id)                  
               if(findIndex >= 0){
                  currentList[findIndex] = p
               }else{
                  currentList.push(p)
               }
            }
         )   
         return currentList
      }
      case 'CLEAR_PROMOTIONS': {
         return []
      }
      case 'UPDATE_PROMOTION': {
         let currentList = [...state]            
         const updatedPromotion = action.payload as Promotions.Promotion
         const findIndex = currentList.findIndex( (c) => c.id ===  updatedPromotion.id)
         if(findIndex >= 0){
            const current = currentList[findIndex]
            currentList[findIndex] = {
               ...current,
               ...updatedPromotion
            }
         }
         return currentList
      }
      case 'DELETE_PROMOTION': {
         let currentList = [...state]
         const promotionId = action.payload as string
         const findIndex = currentList.findIndex((p) => {
            return p.id == promotionId
         })
         if (findIndex >= 0) {
            currentList.splice(findIndex, 1)
         }
         return currentList
      }
      default:
         return state
   }
}

export function clearPromotions(){
   return {
      type: 'CLEAR_PROMOTIONS',
      payload: []
   } as Change
}

export function syncPromotions() {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise(async (resolve, reject) => {
         try {
            const settings = getState().settings
            const casinoId = settings?.linkedCasinoId
            if (casinoId) {
               const response = await Promotions.getPromotions(casinoId,{
                  orderBy: {
                     fieldPath: 'updatedAt',
                     directionStr: 'desc'
                  },
                  limit: 100
               })
               dispatch({
                  type: 'SYNC_PROMOTIONS',
                  payload: response.data
               })
               resolve(response)
            } else {
               reject('No Casino Id found in settings')
            }
         } catch (error) {
            reject(error)
         }
      })
   }
}

export function addPromotion(promotion:Promotions.Promotion) {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise(async (resolve, reject) => {
         try {
            const settings = getState().settings
            const casinoId = settings?.linkedCasinoId
            if (casinoId) {
               const response = await Promotions.addPromotion(casinoId,promotion)
               dispatch({
                  type: 'SYNC_PROMOTIONS',
                  payload: [response]
               })
               resolve(response)
            } else {
               reject('No Casino Id found in settings')
            }
         } catch (error) {
            reject(error)
         }
      })
   }
}

export function updatePromotion(
   promotionId: string,
   promotion: Promotions.Promotion | Partial<Promotions.Promotion>
) {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise(async (resolve, reject) => {
         try {
            const settings = getState().settings
            const casinoId = settings?.linkedCasinoId
            if (casinoId) {
               const response = await Promotions.updatePromotion(
                  casinoId,
                  promotionId,
                  promotion
               )
               dispatch({
                  type: 'UPDATE_PROMOTION',
                  payload: response
               })
               resolve(response)
            } else {
               reject('No Casino Id found in settings')
            }
         } catch (error) {
            reject(error)
         }
      })
   }
}

export function getPromotion(promotionId: string) {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise(async (resolve, reject) => {
         try {
            const settings = getState().settings
            const casinoId = settings?.linkedCasinoId
            if (casinoId) {
               const response = await Promotions.getPromotion(casinoId, promotionId)
               if(response){
                  dispatch({
                     type: 'SYNC_PROMOTIONS',
                     payload: [response]
                  })
               }               
               resolve(response)
            } else {
               reject('No Casino Id found in settings')
            }
         } catch (error) {
            reject(error)
         }
      })
   }
}

export function deletePromotion(promotionId: string) {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      return new Promise<void>(async (resolve, reject) => {
         try {
            const settings = getState().settings
            const casinoId = settings?.linkedCasinoId
            if (casinoId) {
               await Promotions.deletePromotion(casinoId,promotionId)
               dispatch({
                  type: 'DELETE_PROMOTION',
                  payload: promotionId
               })
               resolve()
            } else {
               reject('No Casino Id found in settings')
            }
         } catch (error) {
            reject(error)
         }
      })
   }
}