import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'

export default function Icon(props: any) {
	const { children, onPress, disabled, style, color } = props
	return (
		<ButtonBase onClick={onPress} disabled={disabled} style={{
			opacity: disabled === true ? 0.3 : 1,
			pointerEvents: disabled === true ? 'none': undefined,
			color: color,
			...style
		}}>
			{children}
		</ButtonBase>
	)
}
