/* eslint-disable jsx-a11y/img-redundant-alt */
import { Gifts, Members, Orders, Promotions, Users } from 'cs-connect'
import React, { useEffect, useMemo, useState } from 'react'
import MaterialTable from 'material-table';
import { useHistory, useParams } from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';
import { AppState } from './store';
import { Alert } from './utils/Alert.web';
import Progress from './utils/Progress.web';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SearchBar from './components/SearchBar'
import Button from './components/Button'
import { View, Image, Text } from 'react-native';
import {
  WhereType} from 'firestar'
import { imageForProduct, useDebounce } from './utils';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import EnterAddress from './EnterAddress'
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import ButtonBase from '@material-ui/core/ButtonBase'
import Grid from '@material-ui/core/Grid'

const generateClassName = createGenerateClassName({
	productionPrefix: 'mt',
	seed: 'mt'
}); //To prevent material table from changing appbar style


const INITIAL_PAGESIZE = 15
const PAGESIZE = 10


export default function BulkOrder() {

  const params = useParams() as { promotionId: string }
  const settings = useSelector((state: AppState) => state.settings)
  const casinoId = settings?.linkedCasinoId
  const { promotionId } = params ?? {}
  const [members, setMembers] = useState<Members.Member[]>([])
  const [gifts, setGifts] = useState<Gifts.ShopifyProduct[]>([])
  const [showLoader, setShowLoader] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const history = useHistory()
  const [selectedMemberIds, setSelectedMemberIds] = useState<{[key: string] : Members.Member}>({})
  const [selectedGiftId, setSelectedGiftId] = useState<string>()
  const [validationResult, setValidationResult] = useState<{
    rejected: string[],
    success: string[],
    failed: string[]
  }>()
  const [lastDoc, setLastDoc] = useState<any>()
  const [searchText, setSearchText] = useState('')
  const [showAlert, setShowAlert] = useState<{
    title: string,
    description: string,
	backgroundColor?: string
  } | null>()
  const [showShippingAddress, setShowShippingAddress] = useState(false)
  const [promotion, setPromotion] = useState<Promotions.Promotion>()
  const [searching, setSearching] = useState(false)
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const selectedGift = useMemo(() => {
    return gifts.find(({ id }) => Number(id) === Number(selectedGiftId))
  }, [selectedGiftId, gifts])

  let singleMember : Members.Member | undefined
  if(Object.keys(selectedMemberIds).length === 1){
    singleMember = members.find((m) => m.id === Object.keys(selectedMemberIds)[0])
  }

  useEffect(() => {
    if (casinoId && promotionId) {
      fetchPromotion(casinoId, promotionId)
    }
  }, [casinoId, promotionId])

  useEffect(() => {
    if (promotion != null) {
      fetchGifts()
    }
  }, [promotion])

  useEffect(
    () => {
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm) {
        // Set isSearching state
        //  setIsSearching(true);
        //  // Fire off our API call
        //  searchCharacters(debouncedSearchTerm).then(results => {
        // 	// Set back to false since request finished
        // 	setIsSearching(false);
        // 	// Set results state
        // 	setResults(results);
        //  });
        fetchMembers(debouncedSearchTerm, false)
      } else {
        fetchMembers('', false)
      }
    },
    // This is the useEffect input array
    // Our useEffect function will only execute if this value changes ...
    // ... and thanks to our hook it will only change if the original ...
    // value (searchTerm) hasn't changed for more than 500ms.
    [debouncedSearchTerm]
  );

  async function fetchMembers(searchText: string, paginating: boolean) {
    
    setSearching(true)

    try {
      if(casinoId == null) throw new Error("Casino id not found");

      let where = [] as WhereType[]
      if((searchText ?? '').length > 0){
         const searchTags = (searchText ?? '').split(' ').map((text: string) => text.toLowerCase().trim()).filter((text: string) => text.length > 0)
         where = where.concat(searchTags.map((searchTag) => {
            return {
               fieldPath: `matches.${searchTag}`,
               value: true,
               opStr: '=='
            }
         }))
      }

      const membersResponse = await Members.getMembersForPromotion(casinoId, promotionId,{
        limit: paginating ? PAGESIZE : INITIAL_PAGESIZE,
        where: where,
		lastDoc: paginating ? lastDoc : undefined
      })

      if (paginating) {
        setMembers([...members, ...membersResponse.data])
      } else {
        setMembers(membersResponse.data)
      }
      setLastDoc(membersResponse.lastDoc)
      
    } catch (error) {
        console.log('Error Fetching Members:', error)
        Alert.alert('Error Fetching Members', String(error))
    }
    setSearching(false)
  }

  async function fetchPromotion(casinoId: string, promotionId: string) {
    setShowLoader(true)
    try {
      const promotionResponse = await Promotions.getPromotion(casinoId, promotionId)
      if (promotionResponse == null) {
        throw new Error("Unable to find promotion");
      }
      setPromotion(promotionResponse) 
    } catch (error) {
      console.log('Error Fetching Promotion', error)
    }
    setShowLoader(false)
  }

  async function fetchGifts(){
    setShowLoader(true)
    try {
      console.log('Fetching Gifts for Promotion', promotion?.productIds ?? [])
      const selectedResponse = await Gifts.getGiftsWithProductIds(promotion?.productIds ?? [], false)
      console.log('Recevied Gifts for Promotion:', selectedResponse)
      setGifts(selectedResponse)
    } catch (error) {
      console.log('Error Fetching Gifts:', error)
      Alert.alert('Error Fetching Gifts', String(error))

    }
    setShowLoader(false)
  }

  if(showLoader === true){
    return <Progress/>
  }

  return (
    <Grid container md={12}>
    <div>
      <Dialog fullWidth onClose={() => setShowAlert(null)} open={showAlert != null}>
        <DialogTitle>{showAlert?.title}</DialogTitle>
        <DialogContent>
			<div style={{ 
				padding: '2px 1em', 
				background: showAlert?.backgroundColor,
				borderRadius: 5,
			}}>{showAlert?.description}</div>
		</DialogContent>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
		  padding: '1em'
        }}>
          <div/>
          <Button onPress={() => {
            setShowAlert(null)
            history.replace('/promotions/' + promotionId)
          }} title='Okay' containerStyle={{
            width: 100,
          }} />
        </div>
      </Dialog>
      <Dialog fullWidth onClose={() => setShowShippingAddress(false)} open={showShippingAddress}>
        <DialogTitle>{'Enter Shipping Address'}</DialogTitle>
        <DialogContent>
          <EnterAddress 
            address={singleMember?.shippingAddress}
            onPressUpdate={onAddShippingAddress}
            onPressCancel={() => setShowShippingAddress(false)}/>
        </DialogContent>
        {/* <div style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <div/>
          <Button onPress={() => {
            setShowAlert(null)
            history.replace('/promotions/' + promotionId)
          }} title='Okay' containerStyle={{
            margin: '1.5em',
            width: 100,
          }} />
        </div> */}
      </Dialog>
      <Stepper activeStep={activeStep} orientation='vertical'>
          <Step key={'select-members'} expanded={false}>
            <StepLabel>
              <b>Select Members</b>
              {activeStep !== 0 && (
                <div style={{ marginTop: '0.2em' }}>
                  {singleMember != null ? <div>
                    <div>{singleMember?.cardNumber}</div>
                    {singleMember.shippingAddress ? (
                      <div>
                        <div>{`${singleMember.shippingAddress?.firstName} ${singleMember.shippingAddress?.lastName}`}</div>
                        <div>
                          <div>{singleMember.shippingAddress?.street}</div>
                          <div><span>{singleMember.shippingAddress?.city}</span>&nbsp;<span>{singleMember.shippingAddress?.stateCode}</span></div>
                          <div>{singleMember.shippingAddress?.zipCode}</div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>{singleMember?.name}</div>
                        <div>
                          <div>{singleMember.address?.street}</div>
                          <div><span>{singleMember.address?.city}</span>&nbsp;<span>{singleMember.address?.stateCode}</span></div>
                          <div>{singleMember.address?.zipCode}</div>
                        </div>
                      </div>
                    )}
                  </div> : (
                    <div>
                      {Object.keys(selectedMemberIds).length > 0 && <p>Selected {Object.keys(selectedMemberIds).length} Members</p>}
                    </div>
                  )}
                </div>
              )}
            </StepLabel>
            {activeStep === 0 && <div style={{
              padding: '2em'
            }}>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                  <View/>
                  <SearchBar
                    containerStyle={{
                        backgroundColor: 'white',
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                        padding: 0,
                        width: 400,
                        alignSelf: 'flex-end',
                        marginLeft: 10
                    }}
                    inputContainerStyle={{
                        backgroundColor: '#dddddd',
                        borderRadius: 20
                    }}
                    inputStyle={{
                        color: 'black'
                    }}
                    onClear={() => {
                        setSearchText('')
                    }}
                    placeholder='Search'
                    onChangeText={(text) => setSearchText(text)}
                    value={searchText} />
              </View>
              <br/>
              <StylesProvider generateClassName={generateClassName}>
              <MaterialTable 
                title={'Select Members'}
                options={{
                  selection: false,
                  pageSize: Math.min(members.length, 10),
                  pageSizeOptions: [],
                  search: false
                }}
                isLoading={searching}
                onChangePage={onChangePage}
                columns={[
                  {
                    render: (member) => (
                      <input type='checkbox' checked={selectedMemberIds[member.id] != null} onChange={(event) => {
                        const __selectedMemberIds = {...selectedMemberIds}
                        if(event.target.checked){
                          __selectedMemberIds[member.id] = member
                        }else{
                          delete __selectedMemberIds[member.id]
                        }
                        setSelectedMemberIds(__selectedMemberIds)
                      }}/>
                    )
                  },
				  {
                    field: 'id',
                    title: 'ID',
                    // render: (member) => `${member.firstName} ${member.lastName}`
                  },
                  {
                    field: 'firstName',
                    title: 'Name',
                    // render: (member) => `${member.firstName} ${member.lastName}`
                  },
                  {
                    field: 'lastName',
                    // title: 'Name',
                  },
                  {
                    title: 'Card Number',
                    field: 'cardNumber'
                  },
                  {
                    title: 'Enabled',
                    field: 'enabled'
                  },
                  {
                    title: 'Tier',
                    field: 'tier'
                  },
                  {
                    title: 'Phone Number',
                    field: 'phoneNumber'
                  },
                  {
                    title: 'Email',
                    field: 'email'
                  },
                ]}
                data={members}/>
                </StylesProvider>
                <br/>
                {Object.keys(selectedMemberIds).length > 0 && <p>Selected {Object.keys(selectedMemberIds).length} Members</p>}
                {Object.keys(selectedMemberIds).length === 1 && (promotion?.allowShippingAddress ?? false) && <div>
                  <b>Send this gift to a friend</b>
                  <div style={{ marginTop: '0.5em' }}>
                    <div>
                      {singleMember?.shippingAddress != null && <div>
                        <div>{`${singleMember?.shippingAddress.firstName ?? ''} ${singleMember?.shippingAddress.lastName ?? ''}`.trim()}</div>
                        <div>{`${singleMember?.shippingAddress.street ?? ''}, ${singleMember?.shippingAddress.city ?? ''}`.trim()}</div>
                        <div>{`${singleMember?.shippingAddress.state ?? ''} ${singleMember?.shippingAddress.zipCode ?? ''}`.trim()}</div>
                        <div>{singleMember?.phoneNumber}</div>
                      </div>}
                      <br />
                      <button onClick={() => {
                        setShowShippingAddress(true)
                      }}>{singleMember?.shippingAddress ? 'Update Shipping Address' : 'Gift a friend'}</button>
                      {singleMember?.shippingAddress != null && (
                        <button
                          style={{ marginLeft: '1em' }}
                          onClick={() => {
                            onAddShippingAddress(null)
                          }}>{'Clear'}</button>
                      )}
                    </div>
                  </div>
                </div>}
                <br/>
                <View style={{
                  flexDirection: 'row'
                }}>
                  <Button 
                    onPress={() => {
                      setActiveStep(activeStep + 1)
                    }}
                    disabled={Object.keys(selectedMemberIds).length === 0}
                    containerStyle={{
                      width: 200
                    }} 
                    title='Continue'/>
                </View>                
            </div>}
          </Step>
          <Step key='select-gift' expanded={false}>
              <StepLabel><b>Select Gift</b></StepLabel>
              {activeStep === 1 ? <div>
                <ul style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '-2.5em',
                  flexWrap: 'wrap'
                }}>
              {gifts.map((gift) => {
                    const thumbnail = imageForProduct(gift)
                    const checked = selectedGiftId == gift.id
                    return (
                      <View style={{
                        height: '18em',
                        width: '15em',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '1em',
                     }}>
                        <View style={{
                           flex: 1,
                           borderWidth: checked ? 2 : 0.1,
                           borderColor: checked ? '#224ed4' : '#dddddd',
                           justifyContent: 'space-between',
                           alignItems: 'center',
                           overflow: 'hidden',
                           padding: '1em',
                           borderRadius: 5,
                           backgroundColor: '#dddddd20',
						   width: '100%'
                        }}>
                           <ButtonBase onClick={() => {
                              // if (checked == null) {
                              //    onPressAdd(item)
                              // } else {
                              //    onPressRemove(item)
                              // }
                            setSelectedGiftId(gift.id)
                           }} style={{
                              position: 'absolute',
                              top: 10,
                              right: 10
                           }}>
                              {checked ? (
                                 <CheckBoxOutlined />
                              ) : (
                                 <CheckBoxOutlineBlank />
                              )}
                           </ButtonBase>                     
                           <Image style={{
                              width: 100,
                              height: '100%',
                              flex: 1,
                              resizeMode: 'contain',
                           }} source={{
                              uri: thumbnail
                           }} />
                           <View style={{
                              flex: 0.5,
                              marginTop: '1em'
                           }}>
                              <b>{gift.title}</b>
                              <Text numberOfLines={3} style={{
                                 marginTop: '0.1em',
                                 fontSize: 12,
                              }}>{gift.body_html}</Text>
                           </View>
                        </View>
                     </View>
                      // <li style={{
                      //   padding: '0.1em 0px 0.1em 0px',
                      //   listStyle: 'none'
                      // }}>
                      //   <Paper variant='outlined' sx={{
                      //     display: 'flex',
                      //     justifyContent: 'space-between',
                      //     alignItems: 'center',
                      //     padding: '0.5em'
                      //   }}>
                      //     <div>
                      //       <input
                      //         type={'radio'}
                      //         value={gift.id}
                      //         name='select-gift'
                      //         onChange={(event) => {
                      //           setSelectedGiftId(event.target.value)
                      //         }} />
                      //       &nbsp;&nbsp;&nbsp;
                      //       <label>{gift.title}</label>
                      //     </div>
                      //     <img 
                      //       alt={gift.title}
                      //       style={{
                      //         width: 50,
                      //         height: 50
                      //       }}
                      //       src={thumbnail}/>
                      //   </Paper>
                      // </li>
                      
                    )
                  })}
                </ul>
                {/* <MaterialTable 
                  style={{
                    width: 600
                  }}
                  title={'Select Gift'}
                  options={{
                    selection: true,
                    search: false,
                    pageSize: gifts.length,
                    pageSizeOptions: [],
                    showSelectAllCheckbox: false
                  }}
                  components={{
                    Pagination: () => null
                  }}
                  onSelectionChange={(data, rowData) => {
                    setSelectedGiftId(rowData?.id)
                  }}
                  columns={[
                    {
                      title: 'Image',
                      render: (gift) => {
                        const thumbnail = imageForProduct(gift)
                        return (
                          
                        )
                      }
                    },
                    {
                      title: 'Title',
                      field: 'title'
                    },
                  ]}
                  data={gifts}/> */}
                  <br/>
                  <View style={{
                    flexDirection: 'row'
                  }}>
                    <Button 
                      onPress={() => setActiveStep(activeStep + 1)}
                      disabled={selectedGiftId == null}
                      containerStyle={{
                        width: 200
                      }} 
                      title='Continue'/>
                    <Button 
                      onPress={() => setActiveStep(activeStep - 1)}
                      containerStyle={{
                        width: 200
                      }} 
                      type='text' 
                      title='Back'/>
                  </View>
              </div> : (
                <div style={{
                  marginLeft: '2em'
                }}>
					{selectedGift && (
						<div>
							{(() => {
								const thumbnail = imageForProduct(selectedGift)
								return (
									<img alt='image' style={{
										width: 200,
										height: 200,
										objectFit: 'contain',
										border: '1px solid #dddddd',
										borderRadius: 10,
										padding: 20
									}} src={thumbnail} />
								)
							})()}
							<div>{selectedGift.title}</div>
						</div>
					)}
                </div>
              )}
          </Step>
          <Step key='validate-orders' expanded={false}>
            <StepLabel><b>Validate Orders</b></StepLabel>
            {activeStep == 2 && <View style={{
              paddingLeft: '2em'
            }}>
              {validationResult != null && (
                <View>
					{validationResult.success.length > 0 && <span style={{
						marginBottom: '5px',
						borderRadius: 5,
						padding: '0.3em 1em'
					}}>{validationResult.success.length} order(s) passed validation check. Click on "Place Order" to complete your order.</span>}
					{validationResult.failed.length > 0 && <span style={{
						marginBottom: '5px',
						borderRadius: 5,
						background: "red",
						padding: '0.3em 1em',
						color: 'white'
					}}>{validationResult.failed.length} order(s) failed validation. This could be due to ineligibility, expiration etc. Reason mentioned below.</span>}
					{validationResult.rejected.length > 0 && <span style={{
						marginBottom: '5px',
						borderRadius: 5,
						background: "brown",
						padding: '0.3em 1em',
						color: 'white'
					}}>{validationResult.rejected.length} order(s) were rejected. This could be due to a network connection issue or a fault in the system.</span>}
					<br />
					{validationResult.failed.length > 0 && <div>
						<label><b>Failed List:</b></label><br />
						{validationResult.failed.map((s) => <label>{s}</label>)}
						<br />
					</div>}
					{validationResult.rejected.length > 0 && <div>
						<label><b>Rejected List:</b></label><br />
						{validationResult.rejected.map((s) => <label>{s}</label>)}
						<br />
					</div>}
                </View>
              )}
              <br/>
              <View style={{
                flexDirection: 'row',
                alignItems: 'center'
              }}>
				<View style={{
					flexDirection: 'row'
				}}>
					{validationResult == null ? (
						<Button
							onPress={() => onPressPlaceOrder(true)}
							containerStyle={{
								width: 300,
								marginRight: '1em'
							}}
							title='Validate' />

					) : (
						<Button
							onPress={() => {
								onPressPlaceOrder()
							}}
							containerStyle={{
								width: 300,
								marginRight: '1em'
							}}
							disabled={validationResult.success.length == 0}
							title='Place Order' />
					)}
					<Button
						type='text'
						onPress={() => {
							setActiveStep(activeStep - 1)
							setValidationResult(undefined)
						}}
						containerStyle={{
							width: 200
						}}
						title='Back' />
				</View>
              </View>
            </View>}
          </Step>
      </Stepper>
    </div>
    </Grid>
  )

  function onChangePage(page: number, pageSize: number) {
    if ((members.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE))) {
       if (lastDoc != null) fetchMembers(searchText, true)
    }
  }

  async function onAddShippingAddress(address: Members.ShippingAddress | null){
    const ___members = [...members]
    if (Object.keys(selectedMemberIds).length === 1) {
      const memberId = Object.keys(selectedMemberIds)[0]
      const index = members.findIndex((m) => m.id === memberId)
      if(index >= 0 && casinoId != null){
        const response = await Members.updateMember(casinoId, promotionId, memberId, {
          shippingAddress: address
        })
        const __member = {
          ...___members[index],
          ...response
        } as Members.Member
        __member.shippingAddress = address
        ___members[index] = __member
        setMembers(___members)
      }
    }
    setShowShippingAddress(false)
  }

  async function onPressPlaceOrder(validateOnly?: true){

    setShowLoader(true)
    try {

      const success = [] as string[]
      const failed = [] as string[]
      const rejected = [] as string[]

      const promises = Object.keys(selectedMemberIds).map((id) => {
        return new Promise(async (resolve, reject) => {
          const member = selectedMemberIds[id]
          try {
            if(member == null){
              throw new Error("Member Not Found");
            }
            if(member.cardNumber == null){
              throw new Error("Card Number Not Found");
            }
            if(casinoId == null){
              throw new Error("Cassino Id Not Found");
            }
            if(selectedGiftId == null){
              throw new Error("Cassino Id Not Found");
            }

            let shouldCreateFallbackEmail = false
            if((member.email ?? '').length === 0 && (member.phoneNumber ?? '').length === 0){
              shouldCreateFallbackEmail = true
            }

            const memberName = ((member.firstName ?? '') + ' ' + (member.lastName ?? '')).trim()
            const userResponse = await Users.createUserAccount(member.phoneNumber, memberName, member.email, shouldCreateFallbackEmail, {
              cardNumber: member.cardNumber,
              casinoId
            })
            console.log('User Response', userResponse)

            const userInfo = Users.getCurrentUserInfo()

            const orderResponse = await Orders.checkout(
              userResponse.uid,
              casinoId,
              promotionId,
              selectedGiftId,
              member.id,
              undefined,
              validateOnly,
              userInfo,
              'bulk'
            )

            if(orderResponse.status === 'success'){
                success.push(`${member.cardNumber} - ${member.name} : Success`)
            } else if(orderResponse.status === 'failed'){
                failed.push(`${member.cardNumber} - ${member.name} : ${(orderResponse as any).message}`)
            }

            console.log('Validation Success', member?.id, member?.name, orderResponse)
            resolve(orderResponse)
          } catch (e) {

            const error = e as any
            rejected.push(`${member?.cardNumber} - ${member?.name} : ${error.code ?? String(error)}`)

            console.log('Validation Failed', error, member?.id, member?.name)
            reject(String(error))
          }
        })
      })

      await Promise.allSettled(promises) 
      
      setValidationResult({
        failed: failed,
        rejected: rejected,
        success: success
      })

      console.log('Response', {
        failed: failed.length,
        rejected: rejected.length,
        success: success.length
      })

      if (!validateOnly) {
        setShowAlert({
          title: 'Order Successful',
          description: `${success.length} order(s) have been successfully placed.`,
		  backgroundColor: '#34eba1'
        })
      }
    } catch (error) {
      console.log('Error Placing Orders', error)
      Alert.alert('Error Placing Orders', String(error))
    }
    setShowLoader(false)
  }

}
