import {
   combineReducers
} from 'redux'
import settings from './settings'
import casino from './casino'
import promotions from './promotions'

export interface Change {
   type: ActionTypes,
   payload?: any
}

const appReducer = combineReducers({
   settings: settings,
   casino: casino,
   promotions: promotions
})

export default (state = {}, action: Change) => {
   switch (action.type) {
      default:
         return appReducer(state as any, action)
   }
}

export type AppState = ReturnType<typeof appReducer>

type ActionTypes = 'LINK_CASINO_ID' | 'GET_CASINO' | 'SYNC_PROMOTIONS' | 'CLEAR_PROMOTIONS' | 'DELETE_PROMOTION' | 'UPDATE_PROMOTION' | 'CLEAR_CASINO'
