import { Users } from "cs-connect";
import { RoleIdOptions } from "cs-connect/lib/Users";
import React, { useContext } from "react";
import { RoleContext } from "../App";

interface Props {
  minimumRole: Users.RoleId;
  children: React.ReactNode,
  type?: 'hide' | 'disable' | 'fade'
}

export default function CheckPermission(props: Props) {
  const roleContext = useContext(RoleContext);
  const showChildren = isAuthorized(props.minimumRole, roleContext.role)
  const type = props.type ?? 'hide'

  if(showChildren) return <>{props.children}</>

  if(type == 'hide') return <div></div>

  return (
    <div style={{
      pointerEvents: (type == 'disable' || type == 'fade')? 'none' : undefined,
      opacity: (type == 'disable' || type == 'fade')? 0.7 : 1,
    }}>
      {props.children}
    </div>
  );
}

export function isAuthorized(minimumRole: Users.RoleId, role: Users.Role | null) {
  const roleIds = Object.keys(RoleIdOptions);
  const minimumRoleIndex = roleIds.findIndex((r) => r === minimumRole);
  const currentRoleIndex = roleIds.findIndex((r) => r === role?.level);
  return currentRoleIndex <= minimumRoleIndex;
}
