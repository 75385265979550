import React, { useState } from 'react'
import TextField from './utils/TextField'
import { Members } from 'cs-connect'
import lodash from 'lodash'
import { getStates } from 'cs-connect/lib/Utils'
import { formatPhoneNumber } from './MembersList'

export const StateOptions = {
    null: '--'
 }
 getStates().forEach((state) => {
    StateOptions[state.code] = state.name
 })

export default function EnterAddress(props:{
    address?: Members.ShippingAddress | null
    onPressUpdate: (address: Members.ShippingAddress) => void,
    onPressCancel: () => void
}) {

    const [shippingAddress, setShippingAddress] = useState<Members.ShippingAddress>({
        country: 'United States',
        ...(props.address ?? {})
    } as Members.ShippingAddress)

    return (
        <div>
            <label>Please enter the friend's phone and shipping address for us to deliver.</label>
            <br />
            <br />
            <TextField 
                value={shippingAddress.firstName}
                label='First Name' 
                placeholder='First Name'
                onChangeText={(value) => onChangeValue('firstName', value)} />
            <br />
            <TextField 
                value={shippingAddress.lastName}
                label='Last Name' 
                placeholder='Last Name'
                onChangeText={(value) => onChangeValue('lastName', value)} />
            <br />
            <TextField 
                value={shippingAddress.street}
                label='Street' 
                placeholder='Street'
                onChangeText={(value) => onChangeValue('street', value)} />
            <br />
            <TextField 
                value={shippingAddress.city}
                label='City' 
                placeholder='City'
                onChangeText={(value) => onChangeValue('city', value)} />
            <br />
            <select
                style={{
                    width: '100%',
                    marginTop: 5,
                    padding: '1em',
                    fontSize: 16,
                    flex: 1,
                    border: `1px solid #dddddd`
                }}
                onChange={(event) => {
                    onChangeValues({
                        state: StateOptions[event.target.value],
                        stateCode: event.target.value
                    })
                }}
                value={shippingAddress.stateCode}>
                {Object.keys(StateOptions).map((key) => (
                    <option value={key}>{(StateOptions ?? {})[key]}</option>
                ))}
            </select>
            <br /><br />
            <TextField 
                value={shippingAddress.country}
                label='Country'
                placeholder='Country'
                onChangeText={(value) => onChangeValue('country', value)} />
            <br />
            <TextField
                style={{
                    display: 'flex',
                    flex: 1
                }}
                onBlur={() => onChangeValue('phoneNumber', formatPhoneNumber(shippingAddress.phoneNumber))}
                value={shippingAddress.phoneNumber}
                label='Phone Number'
                placeholder='Phone Number'
                onChangeText={(value) => onChangeValue('phoneNumber', value)} />
            <br />
            <TextField 
                value={shippingAddress.zipCode}
                label='Zip Code' 
                placeholder='Zip Code'
                onChangeText={(value) => onChangeValue('zipCode', value)} />
            <br />
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
            }}>
                <button onClick={() => props.onPressCancel()}>Cancel</button>&nbsp;&nbsp;
                <button onClick={() => props.onPressUpdate(shippingAddress)}>Update</button>
            </div>
        </div>
    )

    function onChangeValue(path: string, value: any) {
        const __shippingAddress = { ...shippingAddress } as Members.ShippingAddress
        lodash.set(__shippingAddress, path, value)
        setShippingAddress(__shippingAddress)
    }

    function onChangeValues(values: Partial<Members.Member>){
        const __member = {...shippingAddress}
        setShippingAddress({
            ...__member,
            ...values
        })
    }
    
}
