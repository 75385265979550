import React from 'react'
import {
   View,
   StyleProp,
   ViewStyle,
   Text
} from 'react-native'

export function SectionPanel(props: {
   title: string,
   render: () => any,
   style?: StyleProp<ViewStyle>
}) {
   const { render, title, style } = props
   return (
      <View style={[{
         paddingVertical: 10
      }, style]}>
         <Text style={{
            marginBottom: 5,
            fontWeight: '500'
         }}>{title}</Text>
         {<View>
            {render()}
         </View>}
      </View>
   )
}