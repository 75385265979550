import { Change, AppState } from '.'

export interface SettingsType {
   linkedCasinoId: string,
}

const initialState: SettingsType | null = null

export default function (state = initialState, action: Change): SettingsType | null {
   switch (action.type) {
      case 'LINK_CASINO_ID':
         return {
            linkedCasinoId: action.payload
         }
      default:
         return state
   }
}

export function linkCasinoId(casinoId: string) {
   return {
      type: 'LINK_CASINO_ID',
      payload: casinoId
   } as Change
}

export function unlinkCasino() {
   return (dispatch: (change: Change) => void, getState: () => AppState) => {
      dispatch({
         type: 'LINK_CASINO_ID',
         payload: null
      } as Change)
      dispatch({
         type: 'CLEAR_PROMOTIONS',
         payload: null
      } as Change)
      dispatch({
         type: 'CLEAR_CASINO',
         payload: null
      } as Change)
   }
}