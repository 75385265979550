import React from 'react'

export default function CheckBox(props: any) {
	const { checked, onPress, title } = props
	return (
		<div style={{ padding: '5px 0px' }}>
			<input
				style={{ marginRight: '1em' }}
				onClick={() => {
					onPress()
				}}
				checked={checked}
				type='checkbox' />
			<label>{title}</label>
		</div>
	)
}
