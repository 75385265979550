import React from 'react'
import { View, Text } from 'react-native'
import {
   Switch,
   Route,
   useLocation,
   Link
} from 'react-router-dom'
import Dashboard from './Dashboard';
import Promotions from './Promotions';
import Users from './Users';
import CreatePromotion from './CreatePromotion'
import MembersList from './MembersList'
import SelectGifts from './SelectGifts'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Vouchers from './Vouchers';
import Settings from './Settings';
import BulkOrder from './BulkOrder';
import Member from './Member';

const routes = [
   {
      path: '/',
      component: Promotions
   },
   {
      path: '/dashboard',
      component: Dashboard
   },
   {
      path: '/promotions',
      component: Promotions
   },
   {
      path: '/users',
      component: Users
   },
   {
      path: '/settings',
      component: Settings
   },
//    {
//       path: '/promotions/create',
//       component: CreatePromotion
//    },
   {
      path: '/promotions/:promotionId',
      component: CreatePromotion
   },
   {
      path: '/promotions/:promotionId/members',
      component: MembersList
   },
   {
      path: '/promotions/:promotionId/members/:memberId',
      component: Member
   },
   {
      path: '/promotions/:promotionId/select-gifts',
      component: SelectGifts
   },
   {
      path: '/promotions/:promotionId/vouchers',
      component: Vouchers
   },
   {
      path: '/promotions/:promotionId/bulk-order',
      component: BulkOrder
   }
]

export default function Main() {

   const location = useLocation()
   let components = location.pathname.split('/').filter(p => p.trim().length > 0)
   const paths = components.map(
      (component,index) => [...components.slice(0,index),component].join('/')
   )
   return (
      <View>
         <View style={{
            marginLeft: 20
         }}>
            <Breadcrumbs>
               {paths.map((path, index) => {
                  const component = components[index]
                  return (
                     <Link style={{
                        textDecoration: 'none',
                        color: 'black'
                     }} to={`/${path}`}>{component}</Link>
                  )
               })}
            </Breadcrumbs>
         </View>
         <Switch>
            {routes.map(({ path, component }) => (
               <Route exact path={path} component={component} />
            ))}
         </Switch>
      </View>
   )
}
