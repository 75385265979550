import React from 'react'
import {
   Text,
   StyleProp,
   ViewStyle,
   View,
   TextInput,
   KeyboardTypeOptions,
   TextStyle
} from 'react-native'

export default function TextField(props: {
   ref?:any,
   defaultValue?: any,
   value?: any,
   placeholder?: string,
   label?: string,
   style?: StyleProp<ViewStyle>,
   errorMessage?: string,
   multiline?: boolean,
   inputStyle?: StyleProp<TextStyle>,
   onChangeText: (text: string) => void,
   onBlur?: () => void,
   description?: string,
   editable?:boolean,
   autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters',
   keyboardType?:KeyboardTypeOptions,
   autoCorrect?:boolean,
   onSubmitEditing?:() => void,
   autoFocus?:boolean,
   secureTextEntry?:boolean,
   showOutline?: boolean
}) {
   const { label, style, placeholder, errorMessage, multiline, value, onChangeText, onBlur, description, inputStyle, editable, autoCapitalize, defaultValue, keyboardType, autoCorrect, onSubmitEditing, autoFocus, secureTextEntry, showOutline } = props
   return (
      <View style={[{
         paddingVertical: 2,
         opacity: (editable ?? true) == true ? 1 : 0.4
      }, style]}>
         {label != null && <Text style={{
            top: -2,
            zIndex: 1,
            position: 'absolute',
            left: 15,
            backgroundColor: 'white'
         }}>{label}</Text>}
         <TextInput
            autoFocus={autoFocus}
            autoCapitalize={autoCapitalize ?? 'none'}
            autoCorrect={autoCorrect ?? false}
            editable={editable ?? true}
            onChangeText={onChangeText}
            defaultValue={defaultValue ?? value}
            value={value}
            onBlur={onBlur}
            multiline={multiline ?? false}
            placeholder={placeholder}
            keyboardType={keyboardType}
            secureTextEntry={secureTextEntry}
            onSubmitEditing={onSubmitEditing}
            placeholderTextColor='gray'
            style={[{
               height: 55,
               fontSize: 17,
               marginTop: label != null ? 5 : 0,
               borderWidth: (showOutline ?? true) == false ? undefined : 1,
               borderRadius: (showOutline ?? true) == false ? undefined : 3,
               paddingHorizontal: 15,
               borderColor: (errorMessage == null) ? '#dddbdb' : 'red',
               borderBottomWidth: (showOutline ?? true) == false ? undefined : 2
            }, inputStyle]} />
         {description != null && <Text style={{
            fontStyle: 'italic',
            color: '#726e6e',
            marginTop: 2
         }}>{description}</Text>}
      </View>
   )
}